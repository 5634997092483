import React, {useCallback} from 'react';
import {WelcomePageWrapper} from "./WelcomePage.style";
import Grid from '@mui/material/Unstable_Grid2';
import {useUserSessionStore} from "../../stores/UserSessionStore";
import {NavLink} from "react-router-dom";
import {Button} from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import UserApi from "../../api/UserApi";
import {useProjectsStore} from "../../stores/ProjectsStore";

interface WelcomePageProps {
}

const WelcomePage = (props: WelcomePageProps) => {
    const {flatpicUser} = useUserSessionStore();
    const {activeProject} = useProjectsStore();
    const determineGreeting = useCallback(() => {
        if (!flatpicUser) {
            return <></>;
        }
        if (!flatpicUser.name) {
            return <> <h3>Hallo</h3>
                <p>Bitte hinterlege <NavLink to="/my-profile">HIER</NavLink> deinen Namen im System.</p>
            </>
        }

        return <h3>Hallo {flatpicUser.name}</h3>;
    }, [flatpicUser])

    const fetchKeywordFile = useCallback(async () => {
        if (!flatpicUser || !activeProject) {
            return;
        }
        const response = await UserApi.INSTANCE.downloadKeywordFile(flatpicUser.userId, activeProject.tenantId, activeProject.projectId)
        const downloadLink = document.createElement('a');
        const blob = await fetch(`data:text/plain;base64,${response.keywords}`)
            .then(res => res.blob())
        const imageUrl = URL.createObjectURL(blob);
        downloadLink.href = imageUrl;
        downloadLink.download = `keywords.txt`;
        setTimeout(() => {
            URL.revokeObjectURL(imageUrl)
        }, 100)

        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    }, [flatpicUser, activeProject])

    return <WelcomePageWrapper>
        <Grid container spacing={2}>
            <Grid xs={12}>
                {determineGreeting()}
            </Grid>
            <Grid xs={4}>
                <Button variant="outlined"
                        size="large"
                        startIcon={<DownloadIcon/>}
                        onClick={() => fetchKeywordFile()}>
                    Stichwort-Liste downloaden
                </Button>
            </Grid>
            <Grid xs={4}>

            </Grid>
            <Grid xs={8}>

            </Grid>
        </Grid>
    </WelcomePageWrapper>;
};

export default WelcomePage;