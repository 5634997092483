import {InputPasswordStepWrapper} from "./InputPasswordStep.style";
import {Button, TextField} from "@mui/material";
import React, {useEffect, useState} from "react";
import {useUserSessionStore} from "../../../../stores/UserSessionStore";

interface InputPasswordStepProps {
    nextStep: () => void;
    code: string;
    email: string;
}

const InputPasswordStep = (props: InputPasswordStepProps) => {
    const {code, email, nextStep} = props;
    const {forgotPasswordSubmit} = useUserSessionStore();
    const [password, setPassword] = useState<string>("");
    const [passwordConfirm, setPasswordConfirm] = useState<string>("");
    const [isButtonDisabled, setButtonDisabled] = useState<boolean>(true);
    const [errorMessage, setErrorMessage] = useState<string>("");
    const handleSetNewPasswordClick = async () => {
        const result = await forgotPasswordSubmit(email, code, password);
        setErrorMessage(result[0]);
        if (!result) {
            nextStep();
        }
    }

    useEffect(() => {
        const newButtonState = password != "" && password === passwordConfirm;
        setButtonDisabled(!newButtonState);
    }, [passwordConfirm, password]);

    return <InputPasswordStepWrapper>
        <h1>Passwort vergessen</h1>
        <p>Wähle dein neues Passwort:</p>
        <ul>
            <li>Enthält mindestens 1 Zahl</li>
            <li>Enthält mindestens 1 Sonderzeichen</li>
            <li>Enthält mindestens 1 Großbuchstaben</li>
            <li>Enthält mindestens 1 Kleinbuchstaben</li>
        </ul>
        <TextField
            id="email"
            name="email"
            label="Password"
            type="password"
            value={password}
            fullWidth
            onChange={(event) => setPassword(event.target.value)}/>
        <TextField
            id="email"
            name="email"
            label="Password wiederholen"
            type="password"
            value={passwordConfirm}
            fullWidth
            onChange={(event) => setPasswordConfirm(event.target.value)}/>
        <Button onClick={handleSetNewPasswordClick}
                disabled={isButtonDisabled}
                color="primary"
                variant="contained"
                fullWidth>Passwort zurücksetzen</Button>
        <p>{errorMessage}</p>
    </InputPasswordStepWrapper>
}

export default InputPasswordStep;