import {create} from "zustand";
import Project from "../domain/Project";
import TenantApi from "../api/TenantApi";
import UserApi from "../api/UserApi";
import {determineStorageValue} from "./determineStorageValue";

export type ProjectsStore = {
    projects: Array<Project>,
    selectedProjectId: string,
    selectedProject: Project | undefined,
    activeProject: Project | undefined,
    activateProject: (projectId: string) => void,
    setSelectedProjectId: (projectId: string) => void,
    unselectProject: () => void;
    fetchAllProjectsForTenant: (tenantId: string) => Promise<Project[]>,
    fetchAllProjectsForCrew: (userId: string) => Promise<Project[]>,
    createProject: (project: Pick<Project, "name" | "dateFrom" | "dateUntil" | "hashtag" | "tenantId" | "imageDescription">) => Promise<boolean>,
    updateProject: (project: Project) => Promise<boolean>
}
const ACTIVE_PROJECT_STORAGE_KEY: string = "ACTIVE_PROJECT_STORAGE_KEY";

export const useProjectsStore = create<ProjectsStore>()((set, get) => ({
    projects: [],
    selectedProjectId: '',
    selectedProject: undefined,
    activeProject: determineStorageValue(ACTIVE_PROJECT_STORAGE_KEY),
    activateProject: (projectId: string) => {
        const {projects} = get();
        const activeProject = projects.find(p => p.projectId === projectId);
        set(state => ({
            ...state,
            activeProject
        }));
        localStorage.setItem(ACTIVE_PROJECT_STORAGE_KEY, JSON.stringify(activeProject));
    },
    setSelectedProjectId: (projectId: string) => {
        const {projects} = get();
        const selectedProject = projects.find(p => p.projectId === projectId);
        set(state => ({
            ...state,
            selectedProjectId: projectId,
            selectedProject
        }));
    },
    unselectProject: () => {
        set(state => ({
            ...state,
            selectedProjectId: undefined,
            selectedProject: undefined
        }));
    },
    fetchAllProjectsForTenant: async (tenantId: string) => {
        const projects = await TenantApi.INSTANCE.fetchAllProjects(tenantId);
        set(state => ({...state, projects}));
        return projects;
    },
    fetchAllProjectsForCrew: async (userId: string) => {
        const projects = await UserApi.INSTANCE.fetchAllProjects(userId);
        set(state => ({...state, projects}));
        return projects;
    },
    createProject: async (project: Pick<Project, "name" | "dateFrom" | "dateUntil" | "hashtag" | "imageDescription" | "tenantId">) => {
        const success = await TenantApi.INSTANCE.createProject(project);
        if (success) {
            get().fetchAllProjectsForTenant(project.tenantId);
        }
        return success;
    },
    updateProject: async (project: Project) => {
        const success = await TenantApi.INSTANCE.updateProject(project);
        if (success) {
            get().fetchAllProjectsForTenant(project.tenantId);
        }
        return success;
    }
}));

