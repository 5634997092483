import React, {useCallback} from 'react';
import {MenuItemsWrapper} from "./MenuItems.style";
import provideMenuItems from "../../../shared/MenuItemProvider";
import FlatpicMenus from "../../../FlatpicMenus";
import {useProjectsStore} from "../../../stores/ProjectsStore";
import {useUserSessionStore} from "../../../stores/UserSessionStore";
import {Menu, MenuItem} from "react-pro-sidebar";
import {Link} from "react-router-dom";
import PhotoLibraryIcon from "@mui/icons-material/PhotoLibrary";


interface MenuItemsProps {
}

const MenuItems = (props: MenuItemsProps) => {
    const {activeProject} = useProjectsStore();
    const {userRole} = useUserSessionStore();
    const determineMenuItems = useCallback(() => {
        return provideMenuItems(FlatpicMenus, {
            menuTitleSubstitutionProvider: (title) => {
                if (title === "#activeProjektName#") {
                    return activeProject?.name || title;
                }
                return title;
            },
            shouldShowMenu: (menuId) => {
                if (menuId.neededUserRole) {
                    return menuId.neededUserRole == userRole;
                }
                return !(menuId.id === "projectsettings" && !activeProject);
            }
        })
    }, [activeProject, userRole]);

    return <MenuItemsWrapper>
        {determineMenuItems()}

        <Menu>
            <MenuItem icon={<PhotoLibraryIcon/>}
                      title="Gallery"
                      component={<Link to="/library"/>}>Gallery</MenuItem>
        </Menu>
    </MenuItemsWrapper>;
};

export default MenuItems;