import styled from "styled-components";

export const MyProfilePageWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;

  > div {
    flex-basis: 100%;
    margin-bottom: 10px;

    &:nth-child(2), &:nth-child(3) {
      flex-basis: calc(50% - 45px);
    }
  }
`;