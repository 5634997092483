import {
    CenterPane,
    ColorStripeLeft,
    ColorStripeRight,
    LeftPane,
    LibraryPageWrapper,
    RightPane
} from "./LibraryPage.style";
import LibraryFilter from "./LibraryFilter/LibraryFilter";
import Gallery from "../../components/Gallery/Gallery";
import {useGalleryStore} from "../../stores/GalleryStore";
import {useEffect, useState} from "react";
import {useProjectsStore} from "../../stores/ProjectsStore";
import withSelectedProject from "../../components/withSelectedProject/withSelectedProject";
import rainbow from "../../assets/images/rainbow.png";
import {useLibraryFilterStore} from "../../stores/LibraryFilterStore";
import SelectionList from "./SelectionList/SelectionList";
import LibraryActions from "./LibraryActions/LibraryActions";
import ImageDetails from "./ImageDetails/ImageDetails";
import {useTopicsStore} from "../../stores/TopicsStore";
import withAuthentication from "../../components/withAuthentication/withAuthentication";

interface LibraryPageProps {
}

const LibraryPage = (props: LibraryPageProps) => {
    const [isInitLoading, setIsInitLoading] = useState<boolean>(true);
    const {fetchImagesStream, size, gap} = useGalleryStore();
    const {activeProject} = useProjectsStore();
    const {
        selectedPhotographer,
        selectedSource,
        selectedKeywords,
        selectedTopic,
        selectedDateTimeFrom,
        selectedDateTimeUntil,
        fetchOptionsForProject
    } = useLibraryFilterStore();
    const {fetchForProject} = useTopicsStore();

    const {tenantId, projectId} = activeProject!;
    useEffect(() => {
        if (tenantId && projectId && !isInitLoading) {
            fetchImagesStream({
                tenantId, projectId,
                topic: selectedTopic,
                keywords: selectedKeywords,
                photographer: selectedPhotographer,
                source: selectedSource,
                dateUntil: selectedDateTimeUntil > 0 ? selectedDateTimeUntil : undefined,
                dateFrom: selectedDateTimeFrom > 0 ? selectedDateTimeFrom : undefined
            })
        }
    }, [fetchImagesStream, tenantId, projectId,
        selectedTopic,
        selectedKeywords,
        selectedPhotographer,
        selectedSource,
        selectedDateTimeFrom,
        selectedDateTimeUntil,
        isInitLoading]);

    useEffect(() => {
        if (tenantId && projectId && isInitLoading) {
            fetchOptionsForProject(tenantId, projectId)
                .finally(() => {
                    setIsInitLoading(false);
                });
        }
    }, [isInitLoading, setIsInitLoading, fetchForProject, tenantId, projectId, fetchOptionsForProject]);

    return <LibraryPageWrapper>
        <LeftPane>
            <LibraryFilter/>
            <LibraryActions/>
            <ColorStripeRight src={rainbow}/>
        </LeftPane>
        <CenterPane>
            <Gallery size={size} gap={gap}/>
        </CenterPane>
        <RightPane>
            <ImageDetails/>
            <SelectionList/>
            <ColorStripeLeft src={rainbow}/>
        </RightPane>
    </LibraryPageWrapper>

}
export default withAuthentication(withSelectedProject(LibraryPage));