import {FormControlLabel, Switch} from "@mui/material";
import {useGalleryStore} from "../../../stores/GalleryStore";
import React from "react";

interface MultiSelectionSwitchProps {
}

const MultiSelectionSwitch = (props: MultiSelectionSwitchProps) => {
    const {isMultiSelect, setMultiSelect} = useGalleryStore();
    return <FormControlLabel control={
        <Switch checked={isMultiSelect}
                onChange={(_, checked) => setMultiSelect(checked)}/>
    } label="Mehrfachselektion"/>
}

export default MultiSelectionSwitch;
