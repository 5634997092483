import styled from "styled-components";
import {SideMenuHeaderProps} from "./SideMenuHeader";

export const SideMenuHeaderWrapper = styled.div`
  max-height: 120px;
  text-align: center;
  z-index: 100000;
  transition: all 0.2s cubic-bezier(0, 0, 0.2, 1);
  box-shadow: 0 -15px 20px 20px ${(p: SideMenuHeaderProps) => p.showBottomShadow ? '#00000024' : '#00000000'};
  padding: 20px 20px 0;

  img {
    width: 90%;
    opacity: 1;
    object-fit: contain;
    cursor: pointer;

    &.opacity-0 {
      opacity: 0;
    }

    &.opacity-1 {
      opacity: 1;
      transition: all 300ms;
    }
  }
`;
