import React, {PropsWithChildren, useEffect, useRef} from 'react';
import {SideMenuScrollContainerStyled} from "./SideMenuScrollContainer.style";

export interface OnChangeSizes {
    isAtTop: boolean;
    isAtBottom: boolean;
    isScrollable: boolean;
}

interface SideMenuScrollContainerProps extends PropsWithChildren {
    onChangeSizes?: (states: OnChangeSizes) => void;
}

const SideMenuScrollContainer = (props: SideMenuScrollContainerProps) => {
    const wrapperRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (!wrapperRef.current) {
            return;
        }
        const observer = new MutationObserver((entries) => {
            const target = wrapperRef.current as HTMLDivElement;
            const nodePositions = determineNodePositions(target);
            setTimeout(()=>
                props.onChangeSizes?.(nodePositions),500);
        });
        observer.observe(wrapperRef.current, {
            attributeFilter: ['style'],
            attributes: true,
            subtree: true,
        });

        return () => {
            observer.disconnect();
        }

    }, [wrapperRef, props]);

    useEffect(() => {
        const current = wrapperRef.current;
        if (!current) {
            return;
        }
        const listener = (ev: Event) => {
            const target = ev.target as HTMLDivElement;
            const nodePositions = determineNodePositions(target);
            props.onChangeSizes?.(nodePositions);
        }
        current.addEventListener('scroll', listener);
        return () => {
            current?.removeEventListener('scroll', listener);
        }

    }, [props]);

    useEffect(() => {
        if (!wrapperRef.current) {
            return;
        }

        const target = wrapperRef.current as HTMLDivElement;
        const nodePositions = determineNodePositions(target);
        props.onChangeSizes?.(nodePositions);
    }, [props])
    return <SideMenuScrollContainerStyled ref={wrapperRef}>

        {props.children}

    </SideMenuScrollContainerStyled>;
};const determineNodePositions = (node: HTMLDivElement) => {
    const isAtTop = node.scrollTop === 0;
    const isAtBottom = node.scrollHeight - node.scrollTop - node.offsetHeight === 0;
    const isScrollable = node.scrollHeight !== node.offsetHeight;
    return {isAtTop, isAtBottom, isScrollable};
}


export default SideMenuScrollContainer;