import React, {useState} from 'react';
import {MenusContainer, SideMenuWrapper} from "./SideMenu.style";
import SideMenuHeader from "./SideMenuHeader/SideMenuHeader";
import SideMenuFooter from "./SideMenuFooter/SideMenuFooter";
import SideMenuScrollContainer, {OnChangeSizes} from "./SideMenuScrollContainer/SideMenuScrollContainer";
import MenuItems from "./MenuItems/MenuItems";
import {Menu, MenuItem, useProSidebar} from "react-pro-sidebar";
import {v4 as uuid} from "uuid";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {useProjectsStore} from "../../stores/ProjectsStore";
import SelectProjectDialog from "../SelectProjectDialog/SelectProjectDialog";
import {Tooltip} from "@mui/material";

interface SideMenuProps {
}

const SideMenu = (props: SideMenuProps) => {
    const [isAtTop, setIsAtTop] = useState<boolean>(true);
    const [isAtBottom, setIsAtBottom] = useState<boolean>(true);
    const {collapsed} = useProSidebar();
    const {activeProject} = useProjectsStore();
    const [isSelectProjectDialogOpen, setSelectProjectDialogOpen] = useState<boolean>(false);

    const onMenuSizesChanged = (states: OnChangeSizes) => {
        setIsAtTop(() => states.isAtTop);
        setIsAtBottom(() => states.isAtBottom);
    };

    return <SideMenuWrapper>
        <SideMenuHeader showBottomShadow={!isAtTop}/>
        <MenusContainer>
            <SideMenuScrollContainer onChangeSizes={onMenuSizesChanged}>
                {activeProject && (
                    <Tooltip title={activeProject.name}
                             placement="right">
                        <Menu>
                            <SelectProjectDialog open={isSelectProjectDialogOpen}
                                                 onClose={() => setSelectProjectDialogOpen(false)}/>

                            <MenuItem icon={<InfoOutlinedIcon/>}
                                      key={uuid()}
                                      onClick={() => setSelectProjectDialogOpen(true)}>{activeProject.name}</MenuItem>

                        </Menu>
                    </Tooltip>
                )}
                <MenuItems/>
            </SideMenuScrollContainer>
        </MenusContainer>
        <SideMenuFooter showTopShadow={!isAtBottom}/>
    </SideMenuWrapper>;
}

export default SideMenu;