import React from 'react';
import {Breadcrumbs, Link} from "@mui/material";
import {useNavigate} from "react-router-dom";

export type Crumb = { name: string, to: string };
export type Crumbs = Array<Crumb>;

interface BreadCrumbProps {
    crumbs: Crumbs
}

const BreadCrumb = (props: BreadCrumbProps) => {
    const navigate = useNavigate();

    const createLink = (crumb: Crumb) => {
        return <Link underline="hover"
                     key={crumb.to}
                     color="inherit"
                     onClick={() => navigate(crumb.to)}>
            {crumb.name}
        </Link>;
    }
    return <Breadcrumbs aria-label="breadcrumb">
        {props.crumbs.map(createLink)}
    </Breadcrumbs>
};

export default BreadCrumb;