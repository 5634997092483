import GalleryImage from "./GalleryImage";
import GalleryApi from "../../api/GalleryApi";
import Queue from "queue-promise";

export default class ImageUrlDeterminer {
    static urls: any = {};

    static queue = new Queue({
        concurrent: 70,
        interval: 100
    });

    private constructor() {
    }

    static determineUrl(image: GalleryImage, size: number): string | Promise<string> {
        return (image as any)[('presignedUrl' + size) as any] as string;
        const itemKey = this.createItemKey(image, size);
        return this.hasItem(itemKey) ? this.getUrl(itemKey) : this.createImageUrl(image, size);
    }

    static hasItem(key: string) {
        return !!this.urls[key];
    }

    static getUrl(key: string): string {
        return this.urls[key];
    }

    static createItemKey(image: GalleryImage, size: number): string {
        return `${image.bucketName}/${image.key}_${size}`;
    }

    static async createImageUrl(image: GalleryImage, size: number) {
        return new Promise<string>(resolve => {
            this.queue.enqueue(async () => {
                const {bucketName, key, imageAccessToken} = image;
                const url = await GalleryApi.INSTANCE.getImageUrl(bucketName, key, imageAccessToken, size as any)
                    .then(url => this.setUrlToStorage(image, size, url));
                resolve(url);
            });
        })
    }

    static setUrlToStorage(image: GalleryImage, size: number, url: string) {
        this.urls[this.createItemKey(image, size)] = url;
        return url;
    }
}
