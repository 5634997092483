import {LibraryActionsWrapper} from "./LibraryActions.style";
import ImageSizeSlider from "./ImageSizeSlider";
import MultiSelectionSwitch from "./MultiSelectionSwitch";
import {Button} from "@mui/material";
import {useNavigate,} from 'react-router-dom';
import {useGalleryStore} from "../../../stores/GalleryStore";
import {useCallback} from "react";

interface LibraryActionsProps {
}

const LibraryActions = (props: LibraryActionsProps) => {
    const {activeReader, clearImages} = useGalleryStore();
    const navigate = useNavigate();
    const handleGoBack = useCallback(() => {
        activeReader?.cancel();
        clearImages()
        navigate("/");
    }, [activeReader, navigate]);

    return <LibraryActionsWrapper>

        <ImageSizeSlider/>
        <MultiSelectionSwitch/>
        <div>
            <Button variant="text"
                    onClick={() => handleGoBack()}>zurück</Button>
        </div>
    </LibraryActionsWrapper>
}

export default LibraryActions;