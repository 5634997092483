import React, {Suspense} from 'react';
import {ContentWrapper, MainContent, MainFrameWrapper, SideMenuWrapper} from "./MainFrame.style";
import {Outlet} from "react-router-dom";
import FlatpicFooter from "../../components/FlatpicFooter/FlatpicFooter";
import {ProSidebarProvider} from "react-pro-sidebar";
import withAuthentication from "../../components/withAuthentication/withAuthentication";
import withUserRoleContext from "../../components/withUserRoleContext/withUserRoleContext";
import GlobalGalleryImageUploader from "../../components/GlobalGalleryImageUploader/GlobalGalleryImageUploader";
import PageSkeleton from "../PageSkeleton/PageSkeleton";

interface MainFrameProps {
}

const MainFrame = (props: MainFrameProps) => {
    return <MainFrameWrapper>
        <GlobalGalleryImageUploader/>
        <ProSidebarProvider>
            <SideMenuWrapper/>
            <ContentWrapper>
                <MainContent>
                    <Suspense fallback={<PageSkeleton />}>
                        <Outlet/>
                    </Suspense>
                </MainContent>
                <FlatpicFooter/>
            </ContentWrapper>
        </ProSidebarProvider>
    </MainFrameWrapper>;
};

export default withAuthentication(withUserRoleContext(MainFrame));