import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import {useCallback, useEffect, useState} from "react";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import {useGalleryStore} from "../../stores/GalleryStore";
import ExportImageInput from "../ExportImageInput/ExportImageInput";
import GalleryImage from "../../domain/Gallery/GalleryImage";
import {useProjectsStore} from "../../stores/ProjectsStore";
import GalleryApi from "../../api/GalleryApi";
import DownloadImageRequest from "../../domain/Gallery/DownloadImageRequest";
import {v4} from "uuid";
import LoadingButton from "@mui/lab/LoadingButton";

interface ExportDialogProps {
}

const ExportDialog = (props: ExportDialogProps) => {
    const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
    const {selectedImages, unselectImage} = useGalleryStore();
    const {activeProject} = useProjectsStore();
    const [isBusy, setIsBusy] = useState<boolean>(false);
    const [isDownloadButtonDisabled, setIsDownloadButtonDisabled] = useState<boolean>(false);
    const [imageDescriptions, setImageDescriptions] = useState<Array<string>>([]);
    const handleClose = useCallback(() => {
        setIsDialogOpen(false)
    }, []);

    useEffect(() => {
        setIsDownloadButtonDisabled(selectedImages.length === 0);
    }, [setIsDownloadButtonDisabled, selectedImages.length])


    const onImageDescriptionChanged = useCallback((image: GalleryImage, description: string) => {
        setImageDescriptions(prevState => {
            const key = `${image.bucketName}/${image.key}`;
            prevState[key as any] = description;
            return prevState;
        });
    }, [setImageDescriptions]);

    const handleDownload = useCallback(async () => {
        setIsBusy(true);
        const requestedImages: DownloadImageRequest[] = selectedImages.map(si => ({
            key: si.key,
            bucketName: si.bucketName,
            projectId: si.projectId,
            tenantId: si.tenantId,
            imageAccessToken: si.imageAccessToken,
            imageDescription: imageDescriptions[`${si.bucketName}/${si.key}` as any]
        }));
        try {
            const response = await GalleryApi.INSTANCE.downloadImages(requestedImages);

            const downloadLink = document.createElement('a');
            if (requestedImages.length == 1) {
                const blob = await fetch(`data:image/jpeg;base64,${response.image}`)
                    .then(res => res.blob())
                const imageUrl = URL.createObjectURL(blob);
                downloadLink.href = imageUrl;
                downloadLink.download = `${v4()}.jpg`;
                setTimeout(() => {
                    URL.revokeObjectURL(imageUrl)
                }, 100)
            } else {
                downloadLink.href = response;
                downloadLink.download = `${v4()}.zip`;
            }
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
        } catch (e) {
            console.error(e)
        }
        setIsBusy(false);
        handleClose();
    }, [selectedImages, imageDescriptions, handleClose]);

    return <>
        <Button onClick={() => setIsDialogOpen(true)}
                disabled={isDownloadButtonDisabled}>
            <CloudDownloadIcon/></Button>
        {isDialogOpen && <Dialog open={isDialogOpen}
                                 onClose={handleClose}
                                 scroll="paper">
            <DialogTitle id="scroll-dialog-title">Download</DialogTitle>
            <DialogContent dividers={true}>
                {selectedImages.map(selectedImage =>
                    <ExportImageInput image={selectedImage}
                                      key={selectedImage.key}
                                      imageDescriptionPattern={activeProject?.imageDescription!}
                                      imageDescriptionChanged={imageDescription => onImageDescriptionChanged(selectedImage, imageDescription)}/>)}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Abbrechen</Button>
                <LoadingButton onClick={handleDownload}
                               loading={isBusy}
                               color="primary">Download</LoadingButton>
            </DialogActions>
        </Dialog>}
    </>
}

export default ExportDialog;