import {ComponentType, useCallback, useEffect, useState} from "react";
import {useUserSessionStore} from "../../stores/UserSessionStore";
import {useLocation, useNavigate} from "react-router-dom";
import {useTenantStore} from "../../stores/TenantStore";
import {FLATPIC_USER_ROLE} from "../../domain/FlatpicUser";
import {useProjectsStore} from "../../stores/ProjectsStore";
import Project from "../../domain/Project";

interface withSelectedTenantProps {
}

export default function withSelectedProject(WrappedComponent: ComponentType) {
    return function (_props: withSelectedTenantProps) {
        const [isCheckingAccess, setIsCheckingAccess] = useState<boolean>(true);
        const {userRole, flatpicUser, fetchCurrentSession} = useUserSessionStore();
        const {selectedTenant} = useTenantStore();
        const {
            activeProject,
            fetchAllProjectsForTenant,
            fetchAllProjectsForCrew,
            unselectProject
        } = useProjectsStore();
        const navigate = useNavigate();
        const location = useLocation()

        const forwardToProjectSelection = useCallback(() => {
            switch (userRole) {
                case FLATPIC_USER_ROLE.TENANT:
                    setTimeout(() =>
                        navigate("/select-project", {state: {prevPath: location.pathname}}), 0);
                    break;
                case FLATPIC_USER_ROLE.CREW:
                    setTimeout(() =>
                        navigate("/select-project", {state: {prevPath: location.pathname}}), 0);
                    break;
            }
        }, [userRole, navigate, location]);

        const proofIfUserHasAccessToProject = useCallback(async () => {
            if (!flatpicUser) {
                throw new Error("no flatpic user was found.");
            }
            if (!selectedTenant) {
                throw new Error("no selected tenant was found.");
            }
            let projects: Project[] = [];
            switch (userRole) {
                case FLATPIC_USER_ROLE.TENANT:
                    projects = await fetchAllProjectsForTenant(selectedTenant?.tenantId);
                    break;
                case FLATPIC_USER_ROLE.CREW:
                    projects = await fetchAllProjectsForCrew(flatpicUser.userId);
                    break;
            }
            const hasAccess = projects.map(project => project.projectId).includes(activeProject?.projectId ?? '');
            if (!hasAccess) {
                unselectProject();
                forwardToProjectSelection();
            }
            setIsCheckingAccess(false);
        }, [flatpicUser, selectedTenant, userRole,
            fetchAllProjectsForTenant, fetchAllProjectsForCrew,
            activeProject,
            unselectProject, forwardToProjectSelection, setIsCheckingAccess]);

        useEffect(() => {
            if (!flatpicUser) {
                fetchCurrentSession();
                return;
            }
            if (!activeProject) {
                forwardToProjectSelection();
            } else {
                proofIfUserHasAccessToProject();
            }
        }, [flatpicUser, activeProject, forwardToProjectSelection, proofIfUserHasAccessToProject]);

        const render = () => {
            if (isCheckingAccess) {
                return <></>;
            }

            return <WrappedComponent/>;
        }

        return render();
    };
}
