import styled from "styled-components";
import {SideMenuFooterProps} from "./SideMenuFooter";


export const SideMenuFooterWrapper = styled.div`
  //height: 150px;
  display: flex;
  flex-direction: column;
  z-index: 100000;
  transition: all 0.2s cubic-bezier(0, 0, 0.2, 1);
  box-shadow: 0 15px 20px 20px ${(p: SideMenuFooterProps) => p.showTopShadow ? '#00000024' : '#00000000'};
`;

export const Expander = styled.div`
  flex: 1;
  text-align: center;
  margin-top: 20px;
`;

export const IconBar = styled.div`
  display: flex;
  justify-content: center;
  gap: 15px;
  align-items: center;

  &.horizontal {
    flex-direction: column-reverse;
  }

`;