import React, {useCallback} from 'react';
import {GalleryWrapper} from "./Gallery.style";
import ImageTile from "./ImageTile/ImageTile";
import {useGalleryStore} from "../../stores/GalleryStore";

interface SelectedGalleryProps {
    size?: number;
    gap?: number;
}

const SelectedGallery = (props: SelectedGalleryProps) => {
    const {selectedImages} = useGalleryStore();
    const size: number = props.size || 100;
    const renderGallery = useCallback(() => {
        return <GalleryWrapper size={size} gap={props.gap}>
            {selectedImages.map((item, index) =>
                <ImageTile key={index}
                           image={item}
                           imageSize={150}
                           selected={false}/>)}
        </GalleryWrapper>
    }, [size, selectedImages])

    return renderGallery();
};

export default SelectedGallery;