import {ColorStripe, FlatpicBoxWrapper, Headline} from "./FlatpicBox.style";
import {PropsWithChildren} from "react";
import rainbow from "../../assets/images/rainbow.png";

interface FlatpicBoxProps extends PropsWithChildren {
    headline?: string;
}

const FlatpicBox = (props: FlatpicBoxProps) => {
    const {children, headline} = props;
    return <FlatpicBoxWrapper>
        <ColorStripe src={rainbow}/>
        {headline && <Headline>{props.headline}</Headline>} {children}
    </FlatpicBoxWrapper>
}

export default FlatpicBox;