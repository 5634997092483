import {MyProfilePageWrapper} from "./MyProfilePage.style";
import PageHeader from "../../components/PageHeader/PageHeader";
import FlatpicBox from "../../components/FlatpicBox/FlatpicBox";
import ChangeName from "./ChangeName/ChangeName";
import ChangePassword from "./ChangePassword/ChangePassword";

interface MyProfilePageProps {
}

const MyProfilePage = (props: MyProfilePageProps) => {
    return <MyProfilePageWrapper>
        <PageHeader title="Mein Profil"
                    icon="Profile"/>
        <FlatpicBox>
            <h2>Namen ändern</h2>
            <ChangeName/>
        </FlatpicBox>
        <FlatpicBox>
            <h2>Passwort ändern</h2>
            <ChangePassword/>
        </FlatpicBox>
    </MyProfilePageWrapper>
}

export default MyProfilePage;