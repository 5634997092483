import {useLibraryFilterStore} from "../../../stores/LibraryFilterStore";
import React from "react";
import SingleSelectFilter from "./SingleSelectFilter/SingleSelectFilter";

interface LibraryFilterProps {
}

const TopicFilter = (props: LibraryFilterProps) => {
    const {
        topics,
        selectedTopic,
        setTopic
    } = useLibraryFilterStore();

    return <SingleSelectFilter selectedValue={selectedTopic}
                               options={topics}
                               setValue={setTopic}
                               label="Topics"/>;
}

export default TopicFilter;