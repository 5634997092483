import styled from "styled-components";

export const LibraryPageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: stretch;
  height: 100vh;
  background-color: #efefef;
`;

export const Heading = styled.h3`
  margin: 0;
  font-weight: 100;
`;

export const LeftPane = styled.div`
  width: 280px;
  position: relative;
  background-color: white;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const ColorStripeRight = styled.img.attrs(({src}) => ({
    src: src,
    alt: 'Flatpic Rainbow',
}))`
  width: 5px;
  position: absolute;
  right: 0;
  height: 100%;
  top: 0;
  object-fit: cover;
`;
export const ColorStripeLeft = styled.img.attrs(({src}) => ({
    src: src,
    alt: 'Flatpic Rainbow',
}))`
  width: 5px;
  position: absolute;
  left: 0;
  height: 100%;
  top: 0;
  object-fit: cover;
`;

export const RightPane = styled.div`
  position: relative;
  background-color: white;
  width: 280px;
  padding: 20px;
`;

export const CenterPane = styled.div`
  flex: 1;
  overflow: auto;
  padding: 20px;;
`;