import {NewPasswordRequiredWrapper} from "./NewPasswordRequired.style";
import {useUserSessionStore} from "../../../stores/UserSessionStore";
import React, {useEffect, useState} from "react";
import {Button, TextField} from "@mui/material";
import {useNavigate} from "react-router-dom";
import LogoCorner from "../../../components/LogoCorner/LogoCorner";
import FlatpicBox from "../../../components/FlatpicBox/FlatpicBox";

interface NewPasswordRequiredProps {
}

const NewPasswordRequired = (props: NewPasswordRequiredProps) => {
    const [newPassword, setNewPassword] = useState<string>("");
    const [newPasswordConfirm, setNewPasswordConfirm] = useState<string>("");
    const [isButtonDisabled, setButtonDisabled] = useState<boolean>(true);
    const [errorMessage, setErrorMessage] = useState<string>("");
    const navigate = useNavigate();
    const {completeNewPassword, cognitoUser} = useUserSessionStore();

    useEffect(() => {
        if (!cognitoUser) {
            navigate('/');
        }
    }, [cognitoUser, navigate]);

    const handleSetNewPasswordClick = async () => {
        const result = await completeNewPassword(newPassword);
        if (!result.length) {
            navigate("/")
        }
        setErrorMessage(result[0]);
    }

    useEffect(() => {
        const newButtonState = newPassword !== "" && newPassword === newPasswordConfirm;
        setButtonDisabled(!newButtonState);
    }, [newPassword, newPasswordConfirm]);

    return <NewPasswordRequiredWrapper>
        <LogoCorner/>
        <FlatpicBox>
            <p>Wähle dein neues Passwort:</p>
            <ul>
                <li>Enthält mindestens 1 Zahl</li>
                <li>Enthält mindestens 1 Sonderzeichen</li>
                <li>Enthält mindestens 1 Großbuchstaben</li>
                <li>Enthält mindestens 1 Kleinbuchstaben</li>
            </ul>
            <TextField
                fullWidth
                variant="standard"
                id="password"
                name="password"
                label="Neues Passwort"
                type="password"
                value={newPassword}
                onChange={(event) => setNewPassword(event.target.value)}/>
            <TextField
                fullWidth
                variant="standard"
                id="passwordconfirm"
                name="passwordconfirm"
                label="Password wiederholen"
                type="password"
                value={newPasswordConfirm}
                onChange={(event) => setNewPasswordConfirm(event.target.value)}/>
            <Button onClick={handleSetNewPasswordClick}
                    color="primary"
                    variant="contained"
                    fullWidth
                    disabled={isButtonDisabled}>Passwort zurücksetzen</Button>
            <p>{errorMessage}</p>
        </FlatpicBox>
    </NewPasswordRequiredWrapper>
}

export default NewPasswordRequired;