import {CognitoUser, CognitoUserSession} from "amazon-cognito-identity-js";
import {create} from "zustand";
import {Auth} from "aws-amplify";
import FlatpicUser, {FLATPIC_USER_ROLE} from "../domain/FlatpicUser";
import {determineStorageValue} from "./determineStorageValue";
import UserSession from "../api/UserSession";
import UserApi from "../api/UserApi";

export type UserSessionStore = {
    isLoggedIn: boolean;
    isFetchingSession: boolean;
    session: CognitoUserSession | null;
    cognitoUser: CognitoUser | null;
    flatpicUser: FlatpicUser | null;
    userRole: FLATPIC_USER_ROLE | undefined;
    setUserRole: (userRole: FLATPIC_USER_ROLE) => void;
    unselectUserRole: () => void;
    fetchCurrentSession: () => Promise<void>;
    logout: () => Promise<void>;
    login: (username: string, password: string) => Promise<string[]>;
    signup: (username: string, password: string) => Promise<string[]>;
    completeNewPassword: (newPassword: string) => Promise<string[]>;
    changePassword: (oldPassword: string, newPassword: string) => Promise<string[]>;
    forgotPassword: (username: string) => Promise<any>;
    forgotPasswordSubmit: (username: string, code: string, password: string) => Promise<string[]>;
    updateNames: (name: string, givenName: string) => Promise<string[]>;
}
const USER_ROLE_STORAGE_KEY: string = "USER_ROLE_STORAGE_KEY";

export const useUserSessionStore = create<UserSessionStore>()((set, get) => ({
    isLoggedIn: false,
    session: null,
    cognitoUser: null,
    flatpicUser: null,
    userRole: determineStorageValue<FLATPIC_USER_ROLE>(USER_ROLE_STORAGE_KEY),
    isFetchingSession: true,
    setUserRole: (userRole: FLATPIC_USER_ROLE) => {
        set((state) =>
            ({...state, userRole}));
        localStorage.setItem(USER_ROLE_STORAGE_KEY, JSON.stringify(userRole));
    },
    unselectUserRole: () => {
        set((state) =>
            ({...state, userRole: undefined}));
        localStorage.removeItem(USER_ROLE_STORAGE_KEY);
    },
    fetchCurrentSession: async () => {
        const isFetchingSession = false;
        try {
            const session = await Auth.currentSession();
            const userId = session.getIdToken().payload.sub;
            const email = session.getIdToken().payload.email;
            const emailVerified = session.getIdToken().payload.email_verified;
            const name = session.getIdToken().payload.name;
            const givenName = session.getIdToken().payload.given_name;
            const flatpicUser: FlatpicUser = {userId, email, name, givenName, emailVerified};
            (UserSession as any)._session = session;
            set((state) =>
                ({...state, session, isFetchingSession, isLoggedIn: true, flatpicUser}));
        } catch (ex) {
            set((state) =>
                ({...state, session: null, isFetchingSession, isLoggedIn: false, flatpicUser: null}));
        }
    },
    logout: async () => {
        if (await Auth.signOut()) {
            set((state) =>
                ({...state, session: null, cognitoUser: null, flatpicUser: null, isLoggedIn: false}));
        }
    },
    login: async (username: string, password: string) => {
        try {
            const cognitoUser = await Auth.signIn(username, password);
            set((state) =>
                ({...state, cognitoUser}));
            if (isUserNeededToSetNewPassword(cognitoUser)) {
                return [NEW_PASSWORD_REQUIRED];
            } else {
                await get().fetchCurrentSession();
                return [];
            }
        } catch (e: any) {
            return [determineError(e)]
        }
    },
    signup: async (username: string, password: string) => {
        try {
            const {user} = await Auth.signUp(username, password);
            set((state) =>
                ({...state, cognitoUser: user}));
            return [];
        } catch (e: any) {
            return [determineError(e)]
        }
    },
    completeNewPassword: async (newPassword: string) => {
        try {
            const {cognitoUser} = get();
            await Auth.completeNewPassword(cognitoUser, newPassword);
            return [];
        } catch (e: any) {
            return [determineError(e)]
        }
    },
    changePassword: async (oldPassword: string, newPassword: string) => {
        try {
            const {cognitoUser} = get();
            await Auth.changePassword(cognitoUser, oldPassword, newPassword);
            return [];
        } catch (e: any) {
            return [determineError(e)]
        }
    },
    forgotPassword: async (username: string) => {
        await Auth.forgotPassword(username);
    },
    forgotPasswordSubmit: async (username: string, code: string, password: string) => {
        try {
            await Auth.forgotPasswordSubmit(username, code, password);
            return [];
        } catch (e: any) {
            return [determineError(e)]
        }
    },
    updateNames: async (name: string, givenName: string) => {
        try {
            const {flatpicUser} = get();
            await UserApi.INSTANCE.updateNames(flatpicUser?.userId || '', name, givenName);
            return [];
        } catch (e: any) {
            return [determineError(e)]
        }
    }
}));

export const NEW_PASSWORD_REQUIRED = "NEW_PASSWORD_REQUIRED";

function isUserNeededToSetNewPassword(user: any): boolean {
    return user.challengeName === NEW_PASSWORD_REQUIRED;
}

function determineError(e: Error): string {
    switch (e.message.toLowerCase()) {
        case 'username cannot be empty':
            return 'Du musst eine Email-Adresse angeben!';
        case 'incorrect username or password.':
            return 'Email-Adresse oder Password sind falsch!';
        case 'Password does not conform to policy: Password must have uppercase characters':
            return 'Passwort muss über einen Großbuchstaben verfügen.';
        case 'Attempt limit exceeded, please try after some time.':
            return 'Zu oft versucht, bitte später noch einmal versuchen.';
        default:
            console.error("unknown error", e)
            return 'Es ist ein Fehler aufgetreten!';
    }
}