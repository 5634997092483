import React, {ReactNode, useEffect} from 'react';
import {
    ContextButtonContainer,
    IconContainer,
    LeftButtonContainer,
    PageHeaderWrapper,
    RightButtonContainer,
    TitleContainer
} from "./PageHeader.style";
import BreadCrumb, {Crumbs} from "../BreadCrumb/BreadCrumb";
import determineIcon, {PageHeaderIcon} from "./determineIcon";
import ContextMenu from "../ContextMenu/ContextMenu";

interface PageHeaderProps {
    icon?: PageHeaderIcon
    title: string,
    getLeftButtons?: () => ReactNode;
    getRightButtons?: () => ReactNode;
    contextMenuItems?: () => ReactNode;
    breadcrumbs?: Crumbs;
}

const PageHeader = (props: PageHeaderProps) => {
    const {title} = props;
    useEffect(() => {
        document.title = `Flatpic | ${title}`;
    }, [title]);
    const renderBreadcrumbs = () => {
        return !props.breadcrumbs ? <></> :
            <BreadCrumb crumbs={props.breadcrumbs}/>;
    }

    const renderContextMenu = () => {
        if (!props.contextMenuItems) {
            return <></>
        }
        const optionalParams: any = props.contextMenuItems();
        if (optionalParams.type.toString() !==
            Symbol("react.fragment").toString()) {
            return <></>
        }

        return <ContextMenu>
            {optionalParams.props.children}
        </ContextMenu>;
    }

    return <>
        {renderBreadcrumbs()}
        <PageHeaderWrapper>
            <IconContainer>
                {determineIcon(props.icon)}
            </IconContainer>
            <TitleContainer>
                {props.title}
            </TitleContainer>
            <LeftButtonContainer>
                {props.getLeftButtons?.()}
            </LeftButtonContainer>
            <RightButtonContainer>
                {props.getRightButtons?.()}
            </RightButtonContainer>
            <ContextButtonContainer>
                {renderContextMenu()}
            </ContextButtonContainer>
        </PageHeaderWrapper>
    </>;
};

export default PageHeader;