import {Button} from "@mui/material";
import {useGalleryStore} from "../../../../stores/GalleryStore";
import UnpublishedIcon from '@mui/icons-material/Unpublished';

interface ClearSelectionButtonProps {
}

const ClearSelectionButton = (props: ClearSelectionButtonProps) => {
    const {selectedImages, clearSelection} = useGalleryStore();
    return <Button disabled={selectedImages.length == 0} onClick={clearSelection}
                   title="Auswahl entfernen">
        <UnpublishedIcon></UnpublishedIcon>
    </Button>;
}

export default ClearSelectionButton;