import {useLibraryFilterStore} from "../../../stores/LibraryFilterStore";
import React from "react";
import SingleSelectFilter from "./SingleSelectFilter/SingleSelectFilter";

interface LibraryFilterProps {
}

const PhotographerFilter = (props: LibraryFilterProps) => {
    const {
        photographer,
        selectedPhotographer,
        setPhotographer
    } = useLibraryFilterStore();

    return <SingleSelectFilter selectedValue={selectedPhotographer}
                               options={photographer}
                               setValue={setPhotographer}
                               label="Fotograf"/>;
}

export default PhotographerFilter;