import {UserMenuWrapper} from "./UserMenu.style";
import {Avatar, Divider, IconButton, ListItemIcon, Menu, MenuItem, Tooltip} from "@mui/material";
import React, {useCallback} from "react";
import {Logout, Settings} from "@mui/icons-material";
import {useUserSessionStore} from "../../../../stores/UserSessionStore";
import {useLocation, useNavigate} from "react-router-dom";

interface UserMenuProps {
}

const UserMenu = (props: UserMenuProps) => {
    const {logout} = useUserSessionStore();
    const navigate = useNavigate();
    const location = useLocation();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = useCallback((event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    }, [setAnchorEl]);

    const handleClose = useCallback(() => {
        setAnchorEl(null);
    }, [setAnchorEl]);

    const handleLogout = useCallback(async () => {
        await logout();
        navigate("/login");
        handleClose();
    }, [logout, navigate, handleClose]);

    const handleChangeRole = useCallback(() => {
        navigate("/select-role", {state: {prevPath: location.pathname}})
        handleClose();
    }, [navigate, location, handleClose]);

    const handleMyProfile = useCallback(() => {
        navigate("/my-profile", {state: {prevPath: location.pathname}})
        handleClose();
    }, [navigate, location, handleClose]);

    return <UserMenuWrapper>
        <Tooltip title="Open settings">
            <IconButton size="small"
                        onClick={handleClick}>
                <Avatar alt="Remy Sharp"
                        sx={{width: 30, height: 30}}/>
            </IconButton>
        </Tooltip>
        <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleClose}
            onClick={handleClose}
            PaperProps={{
                elevation: 0,
                sx: {
                    overflow: 'visible',
                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                    mt: -1.5,
                    '& .MuiAvatar-root': {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                    },
                    '&:before': {
                        content: '""',
                        display: 'block',
                        position: 'absolute',
                        bottom: -10,
                        left: 14,
                        width: 10,
                        height: 10,
                        bgcolor: 'background.paper',
                        transform: 'translateY(-50%) rotate(45deg)',
                        zIndex: 0,
                    },
                },
            }}
            transformOrigin={{horizontal: 'left', vertical: 'bottom'}}
            anchorOrigin={{horizontal: 'left', vertical: 'top'}}
        >
            <MenuItem onClick={handleMyProfile}>
                <Avatar/> Mein Profil
            </MenuItem>
            <Divider/>

            <MenuItem onClick={handleChangeRole}>
                <ListItemIcon>
                    <Settings fontSize="small"/>
                </ListItemIcon>
                Rolle wechseln
            </MenuItem>
            <MenuItem onClick={handleLogout}>
                <ListItemIcon>
                    <Logout fontSize="small"/>
                </ListItemIcon>
                Logout
            </MenuItem>
        </Menu>
    </UserMenuWrapper>
}

export default UserMenu;