import styled from "styled-components";

export const FlatpicBoxWrapper = styled.div`
  border: 1px solid rgb(217 217 217);
  border-radius: 5px;
  padding: 20px;
  position: relative;
  overflow: hidden;
  background-color: white;
  
  h1, h2, h3, h4, h5, h6 {
    font-weight: 100;
    font-style: italic;
    margin-top: 0;
  }
`;

export const ColorStripe = styled.img.attrs(({src}) => ({
    src: src,
    alt: 'Flatpic Rainbow',
}))`
  width: 5px;
  position: absolute;
  left: 0;
  height: 100%;
  top: 0;
  object-fit: cover;
`;

export const Headline = styled.h3`
  margin: 0 0 20px;
`;