import React, {useEffect, useState} from 'react';
import {GalleryWrapper, LoadingInfo} from "./Gallery.style";
import ImageTile from "./ImageTile/ImageTile";
import {useGalleryStore} from "../../stores/GalleryStore";
import {LinearProgress} from "@mui/material";

interface GalleryProps {
    size?: number;
    gap?: number;
}

const Gallery = (props: GalleryProps) => {
    const {images, isSelected, isFetching} = useGalleryStore();
    const [imageTileSize, setImageTileSize] = useState<number>(150);
    const size: number = props.size || 300;

    useEffect(() => {
        if (size <= 150) {
            setImageTileSize(150);
        } else if (size <= 300) {
            setImageTileSize(300);
        } else if (size <= 1024) {
            setImageTileSize(1024);
        } else {
            setImageTileSize(2000);
        }

    }, [size, setImageTileSize, imageTileSize])

    if (isFetching && images.length == 0) {
        return <LoadingInfo>
            <h3>Bilder werden geladen</h3>
            <LinearProgress color="inherit"/>
        </LoadingInfo>
    }

    return <GalleryWrapper size={size} gap={props.gap}>
        {images.map((item, index) =>
            <ImageTile key={index}
                       image={item}
                       imageSize={imageTileSize}
                       selected={isSelected(item)}/>)}
    </GalleryWrapper>
};

export default Gallery;