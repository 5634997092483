import {OptionCount, OptionLabel} from "./SingleSelectFilter.style";
import {Divider, FormControl, InputLabel, MenuItem, Select} from "@mui/material";
import {LibraryFilterOption, UNSELECTED_KEY} from "../../../../stores/LibraryFilterStore";
import React, {useCallback} from "react";

interface SingleSelectFilterProps {
    setValue: (value: string) => void;
    selectedValue: string;
    options: LibraryFilterOption[];
    label: string;
    noSelectedOption?: LibraryFilterOption;
}

const SingleSelectFilter = (props: SingleSelectFilterProps) => {
    const {label, selectedValue, setValue, options, noSelectedOption} = props;

    const determineDefaultOption = useCallback(() => {
        const value = noSelectedOption?.value ?? UNSELECTED_KEY;
        const label = noSelectedOption?.label ?? "-";

        return <MenuItem value={value}>{label}</MenuItem>
    }, [noSelectedOption]);

    const determineMenuItem = useCallback((option: LibraryFilterOption) => {
        const isDisabled = false;//option.count === 0;
        return <MenuItem value={option.value}
                         key={option.value}
                         disabled={isDisabled}>
            <OptionLabel>{option.label}</OptionLabel>
            {option.count > 0 && <OptionCount>({option.count})</OptionCount>}
        </MenuItem>
    }, []);
    const determineDivider = useCallback(() => {
        if (options.length)
            return <Divider/>
        return []
    }, [options.length])

    return <FormControl fullWidth
                        size="small"
                        variant="filled">
        <InputLabel>{label}</InputLabel>
        <Select
            labelId="library-source-filter-label"
            value={selectedValue}
            label="Thema"
            onChange={event => setValue(event.target.value)}>
            {determineDefaultOption()}
            {determineDivider()}
            {options.map(determineMenuItem)}
        </Select>
    </FormControl>
}

export default SingleSelectFilter;