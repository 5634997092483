import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter} from 'react-router-dom';
import {LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterMoment} from '@mui/x-date-pickers/AdapterMoment';
import {SnackbarProvider} from 'notistack';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import {deDE as dataGridDeDE} from '@mui/x-data-grid';
import {deDE as coreDeDE} from '@mui/material/locale';
import {deDE} from '@mui/x-date-pickers/locales';
import { LicenseInfo } from '@mui/x-license-pro';
import {Buffer} from 'buffer';

window.Buffer = window.Buffer || Buffer;
const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
const theme = createTheme(
    {
        palette: {
            primary: { main: '#1976d2' },
        },
    },
    deDE, // x-date-pickers translations
    dataGridDeDE, // x-data-grid translations
    coreDeDE, // core translations
);
LicenseInfo.setLicenseKey('eebca63a2bc24555e9ef269b97995009Tz03MDY5NSxFPTE3MjA5NTE4NzkwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=');
root.render(
    <React.StrictMode>
        <BrowserRouter>
            <ThemeProvider theme={theme}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                    <SnackbarProvider maxSnack={5}>
                        <App/>
                    </SnackbarProvider>
                </LocalizationProvider>
            </ThemeProvider>
        </BrowserRouter>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
