import {create} from "zustand";
import Topic from "../domain/Topic";
import {Scene} from "../domain/Scene";
import TenantApi from "../api/TenantApi";

export type TopicsStore = {
    topics: Topic[];
    selectedTopic?: Topic,
    selectedScene: Record<string, Scene>,
    fetchForProject: (tenantId: string, projectId: string) => Promise<Topic[]>;
    update: (topic: Topic) => Promise<boolean>;
    updateScene: (scene: Scene) => Promise<boolean>;
    createTopic: (topic: Topic) => Promise<boolean>;
    createTopics: (tenantId: string, projectId: string, topics: Topic[]) => Promise<boolean>;
    createScene: (scene: Scene) => Promise<boolean>;
    setSelectedTopic: (topicId: string) => void;
    setSelectedScene: (topicId: string, sceneId: string) => void;
    remove: (topic: Topic) => Promise<void>;
    removeScene: (scene: Scene) => Promise<boolean>;
}

export const useTopicsStore = create<TopicsStore>()((set, get) => ({
    topics: [],
    selectedTopic: undefined,
    selectedScene: {},
    fetchForProject: async (tenantId: string, projectId: string) => {
        const topics = await TenantApi.INSTANCE.fetchTopics(tenantId, projectId);
        set((state) => ({...state, topics}));
        return topics;
    },
    update: async (topic: Topic) => {
        const result = await TenantApi.INSTANCE.updateTopic(topic);
        return result;
    },
    updateScene: async (scene: Scene) => {
        const success = await TenantApi.INSTANCE.updateScene(scene);
        if (success) {
            get().fetchForProject(scene.tenantId, scene.projectId);
        }
        return success;
    },
    createTopic: async (topic: Topic) => {
        const success = await TenantApi.INSTANCE.createTopic(topic);
        if (success) {
            get().fetchForProject(topic.tenantId, topic.projectId);
        }
        return success;
    },
    createTopics: async (tenantId: string, projectId: string, topics: Topic[]) => {
        const success = await TenantApi.INSTANCE.createTopics(tenantId, projectId, topics);
        if (success) {
            get().fetchForProject(tenantId, projectId);
        }
        return success;
    },
    createScene: async (scene: Scene) => {
        const success = await TenantApi.INSTANCE.createScene(scene);
        if (success) {
            get().fetchForProject(scene.tenantId, scene.projectId);
        }
        return success;
    },
    setSelectedTopic: (topicId: string) => {
        const {topics} = get();
        const selectedTopic = topics.find(p => p.topicId === topicId);
        set(state => ({
            ...state,
            selectedTopic,
            selectedScene: undefined
        }));
    },
    setSelectedScene: (topicId: string, sceneId: string) => {
        let {selectedScene, topics} = get();
        const topic = topics.find(p => p.topicId === topicId);
        const newSelectedScene = topic?.scenes?.find(s => s.sceneId === sceneId);
        if (!topic || !newSelectedScene)
            return;

        if (!selectedScene)
            selectedScene = {};
        selectedScene[topicId] = newSelectedScene;
        set(state => ({
            ...state,
            selectedScene
        }));
    },
    remove: async (topic: Topic) => {
        await TenantApi.INSTANCE.deleteTopic(topic);
        get().fetchForProject(topic.tenantId, topic.projectId);
    },
    removeScene: async (scene: Scene) => {
        const success = await TenantApi.INSTANCE.deleteScene(scene);
        if (success) {
            get().fetchForProject(scene.tenantId, scene.projectId);
        }
        return success;
    }
}));

