import {FILE_ERROR, FileWithKeyword} from "../../stores/FileUploadStore";
import {useCallback} from "react";
import {useProjectsStore} from "../../stores/ProjectsStore";
import useMetaDataExtractor from "./useMetaDataExtractor";
import {useTopicsStore} from "../../stores/TopicsStore";

export default function useFileValidator() {
    const {activeProject} = useProjectsStore();
    const {topics, fetchForProject} = useTopicsStore();
    const {extractMetaData} = useMetaDataExtractor();

    const validateFile = useCallback(async (file: FileWithKeyword): Promise<{
        errors: FILE_ERROR[],
        topicKeyword: string
    }> => {
        if (!activeProject) {
            return {
                errors: [],
                topicKeyword: ""
            };
        }

        const loadedTopics = topics.length ? topics : await fetchForProject(activeProject.tenantId, activeProject.projectId);
        const errors = [];
        let cleanKeywords: string[] = [];
        try {
            const {keywords, dateTimeOriginal, copyright} = await extractMetaData(file)
            if (!dateTimeOriginal) {
                errors.push(FILE_ERROR.NO_DATE_TIME_ORIGINAL)
            }
            if (!copyright) {
                errors.push(FILE_ERROR.NO_AUTHOR)
            }
            cleanKeywords = keywords?.map(keyword => keyword.startsWith("FP_") ? keyword.replace("FP_", "").toLowerCase() : keyword) ?? [];
        } catch (e) {
            errors.push(FILE_ERROR.NO_IMAGE_FILE);
            return {
                errors: [],
                topicKeyword: ""
            };
        }

        const topicKeywords = loadedTopics.map(t => t.keyword.toLowerCase())
            .filter(k => k);

        const intersectionKeywords = intersection(cleanKeywords, topicKeywords);
        if (intersectionKeywords.length <= 0) {
            errors.push(FILE_ERROR.NO_KEYWORD)
        }

        return {
            errors,
            topicKeyword: intersectionKeywords[0]
        };
    }, [activeProject, extractMetaData, topics, fetchForProject]);

    const intersection = (first: string[], second: string[]) => {
        const s = new Set(second);
        return first.filter(item => s.has(item));
    };
    return {
        validateFile
    }
}

