export enum FLATPIC_USER_ROLE {
    TENANT = "TENANT",
    CREW = "CREW",

}

export default interface FlatpicUser {
    userId: string;
    email: string;
    name: string;
    givenName: string;
    emailVerified: boolean;
}