import ImageIcon from '@mui/icons-material/Image';
import {Slider, Stack} from "@mui/material";
import {useGalleryStore} from "../../../stores/GalleryStore";

interface ImageSizeSliderProps {
}

const ImageSizeSlider = (props: ImageSizeSliderProps) => {
    const {size, setSize} = useGalleryStore();
    return <Stack spacing={2} direction="row" sx={{mb: 1}} alignItems="center">
        <ImageIcon fontSize="small"/>
        <Slider aria-label="Volume"
                value={size}
                min={100}
                max={800}
                onChange={(_, value) => setSize(value as number)}/>
        <ImageIcon fontSize="large"/>
    </Stack>
}

export default ImageSizeSlider;