import React, {useEffect, useState} from 'react';
import {SideMenuHeaderWrapper} from "./SideMenuHeader.style";
import {useProSidebar} from "react-pro-sidebar";
import logoSmall from "../../../assets/images/logo.png";
import logo from "../../../assets/images/logo_text.png";
import {useNavigate} from "react-router-dom";

export interface SideMenuHeaderProps {
    showBottomShadow?: boolean;
}

const SideMenuHeader = (props: SideMenuHeaderProps) => {
    const {collapsed} = useProSidebar();
    const navigate = useNavigate();
    const [opacity, setOpacity] = useState<string>("opacity-1");
    useEffect(() => {
        let timeout: string | number | NodeJS.Timeout | undefined;
        if (collapsed) {
            setOpacity("opacity-0")
            timeout = setTimeout(() => {
                setOpacity("opacity-1");
            }, 200);
        }
        return () => {
            clearTimeout(timeout)
        };
    }, [collapsed])

    return <SideMenuHeaderWrapper showBottomShadow={props.showBottomShadow}>
        {<img src={collapsed ? logoSmall : logo}
              style={{height: collapsed ? '50px' : '100px'}}
              alt="logo" className={opacity}
              onClick={() => navigate("/")}/>}
    </SideMenuHeaderWrapper>;
};


export default SideMenuHeader;