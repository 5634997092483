import MenuContainer, {MenuRoute, MenuStructure} from "./MenuContainer";
import React, {ReactNode} from "react";
import {Route} from "react-router-dom";

export default function provideMenuRoutes(menuContainerList: MenuContainer[]) {
    return menuContainerList.map(m => m.menus)
        .map(createRouteForMenuStructures);
}

function createRouteForMenuStructures(menus: MenuStructure[] | undefined): ReactNode[] {
    if (!menus)
        return [];
    const routes = menus.map(m => m.route)
        .map(createRoute);
    const childRoutes = menus.map(m => m.children)
        .flatMap(createRouteForMenuStructures);

    return [...routes, ...childRoutes];
}

function createRoute(route: MenuRoute): ReactNode {
    if (route.subRoutes) {
        return <Route path={route.to} element={route.element}>
            {route.subRoutes.map(createRoute)}
        </Route>
    }
    return <Route path={route.to} element={route.element}/>
}