import styled from "styled-components";
import {Sidebar} from "react-pro-sidebar";

export const SideMenuWrapper = styled(Sidebar)`
  
  .ps-sidebar-container{
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
  }
`;

export const MenusContainer = styled.div`
  flex-grow: 1;
  position: relative;
`;
export const ScrollBoxWrapper = styled.div`
`;
export const ScrollBox = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  overflow: auto;
`;