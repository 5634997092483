import {Button, CircularProgress, TextField} from "@mui/material";
import {useFormik} from "formik";
import * as yup from "yup";
import {useUserSessionStore} from "../../../stores/UserSessionStore";
import {useCallback, useState} from "react";
import {enqueueSnackbar} from "notistack";
import {green} from "@mui/material/colors";
import { ChangePasswordWrapper} from "./ChangePassword.style";

interface ChangePasswordProps {
}

const validationSchema = yup.object({
    oldPassword: yup
        // @ts-ignore
        .string('Altes Passwort')
        .required('Altes Passwort ist ein Pflichtfeld'),
    password: yup
        // @ts-ignore
        .string('Neues Passwort')
        .required('Neues Passwort ist ein Pflichtfeld'),
    repeatPassword: yup
        // @ts-ignore
        .string('Neues Passwort wiederholen')
        .required('Neues Passwort wiederholen ist ein Pflichtfeld')
        .oneOf([yup.ref('password')], 'Passwörter stimmen nicht überein'),
});

const ChangePassword = (props: ChangePasswordProps) => {
    const [isBusy, setBusy] = useState<boolean>(false);
    const {changePassword} = useUserSessionStore();

    const handleOnSubmit = useCallback(async (oldPassword: string, password: string) => {
        if ((await changePassword(oldPassword, password)).length == 0) {
            enqueueSnackbar(`Dein Passwort wurde aktualisiert`, {
                variant: "success"
            });
        } else {
            enqueueSnackbar(`Fehler beim Aktualisieren deines Passworts`, {
                variant: "error"
            });
        }
    }, [changePassword, enqueueSnackbar]);

    const formik = useFormik({
        initialValues: {
            oldPassword: '',
            password: '',
            repeatPassword: '',
        },
        enableReinitialize: true,
        onSubmit: async (values) => {
            setBusy(true);
            handleOnSubmit(values.oldPassword, values.password).finally(() => setBusy(false));
        },
        validationSchema,
    });

    return <ChangePasswordWrapper>
        <TextField label="Aktuelles Passwort"
                   id="oldPassword"
                   name="oldPassword"
                   type="password"
                   fullWidth
                   variant="standard"
                   value={formik.values.oldPassword}
                   onChange={formik.handleChange}
                   error={formik.touched.oldPassword && Boolean(formik.errors.oldPassword)}
                   helperText={formik.touched.oldPassword && formik.errors.oldPassword}/>
        <TextField label="Neues Passwort"
                   id="password"
                   name="password"
                   type="password"
                   fullWidth
                   variant="standard"
                   value={formik.values.password}
                   onChange={formik.handleChange}
                   error={formik.touched.password && Boolean(formik.errors.password)}
                   helperText={formik.touched.password && formik.errors.password}/>

        <TextField label="Neues Passwort wiederholen"
                   id="repeatPassword"
                   name="repeatPassword"
                   type="password"
                   fullWidth
                   variant="standard"
                   value={formik.values.repeatPassword}
                   onChange={formik.handleChange}
                   error={formik.touched.repeatPassword && Boolean(formik.errors.repeatPassword)}
                   helperText={formik.touched.repeatPassword && formik.errors.repeatPassword}/>


        <Button onClick={formik.handleSubmit as any}
                disabled={!formik.isValid || isBusy}
                color="success"
                variant="outlined"
                size="small">
            {isBusy && (
                <CircularProgress
                    size={24}
                    sx={{
                        color: green[500],
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        marginTop: '-12px',
                        marginLeft: '-12px',
                    }}/>
            )}
            Bestätigen
        </Button>
    </ChangePasswordWrapper>
}

export default ChangePassword;