import {InputCodeStepWrapper} from "./InputCodeStep.style";
import {Button, TextField} from "@mui/material";
import React, {useState} from "react";

interface InputCodeStepProps {
    nextStep: (code:string) => void;
    email: string;
}

const InputCodeStep = (props: InputCodeStepProps) => {
    const {email, nextStep} = props;
    const [code, setCode] = useState<string>("");
    const handleConfirmClick = async () => {
        nextStep(code);
    };

    return <InputCodeStepWrapper>
        <h1>Passwort vergessen</h1>
        <p>Du hast einen Code via Email an <b>{email}</b> erhalten.<br/>Bitte gib ihn hier ein:</p>
        <TextField
            id="code"
            name="code"
            label="Bestätigungscode"
            type="text"
            value={code}
            fullWidth
            onChange={(event) => setCode(event.target.value)}/>
        <Button
            onClick={handleConfirmClick}
            color="primary"
            variant="contained"
            fullWidth>Bestätigen</Button>
    </InputCodeStepWrapper>
}

export default InputCodeStep;