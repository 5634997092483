import React from 'react';
import {Expander, IconBar, SideMenuFooterWrapper} from "./SideMenuFooter.style";
import SideMenuToggle from "../SideMenuToggle/SideMenuToggle";
import {useProSidebar} from "react-pro-sidebar";
import UserMenu from "./UserMenu/UserMenu";

const UserPoolId = process.env.REACT_APP_USER_POOL_ID as string
const IdentityPoolId = process.env.REACT_APP_IDENTITY_POOL_ID as string

export interface SideMenuFooterProps {
    showTopShadow?: boolean
}

const SideMenuFooter = (props: SideMenuFooterProps) => {
    const {showTopShadow} = props;
    const {collapsed} = useProSidebar();

    /*
     *<IconButton color="inherit">
                    <Badge badgeContent={17}
                           color="info">
                        <NotificationsIcon fontSize="inherit"/>
                    </Badge>
                </IconButton>
     */

    return <SideMenuFooterWrapper showTopShadow={showTopShadow}>
        <Expander>
            <IconBar className={collapsed ? 'horizontal' : ''}>
                <UserMenu/>

            </IconBar>

        </Expander>

        <SideMenuToggle/>
    </SideMenuFooterWrapper>

};

export default SideMenuFooter;