import {
    PageSkeletonBody, PageSkeletonHeader,
    PageSkeletonIcon,
    PageSkeletonTitle,
    PageSkeletonWrapper
} from "./PageSkeleton.style";
import {Skeleton} from "@mui/material";

interface PageSkeletonProps {
}

const PageSkeleton = (props: PageSkeletonProps) => {
    return <PageSkeletonWrapper>
        <PageSkeletonHeader>
            <PageSkeletonIcon>
                <Skeleton variant="circular" width="100%" height="100%"/>
            </PageSkeletonIcon>
            <PageSkeletonTitle>
                <Skeleton variant="rectangular" width="100%" height="100%"/>
            </PageSkeletonTitle>
        </PageSkeletonHeader>
        <PageSkeletonBody>
            <Skeleton variant="rectangular" height="100%"/>
        </PageSkeletonBody>
    </PageSkeletonWrapper>;
};

export default PageSkeleton;