import AbstractApi from "./AbstractApi";
import GalleryImageResponse from "../domain/Gallery/GalleryImageResponse";
import DownloadImageRequest from "../domain/Gallery/DownloadImageRequest";
import GalleryImage from "../domain/Gallery/GalleryImage";
import UserSession from "./UserSession";

export interface FetchOptions {
    projectId: string;
    tenantId: string;
    photographer?: string;
    topic?: string;
    keywords?: string[];
    source?: string;
    dateFrom?: number;
    dateUntil?: number;
    dryRun?: boolean;
}

export default class GalleryApi extends AbstractApi {
    public static _instance: GalleryApi;

    private constructor() {
        super("Gallery", process.env.REACT_APP_GALLERY_API);
    }

    public static get INSTANCE(): GalleryApi {
        if (!this._instance) {
            this._instance = new GalleryApi()
        }
        return this._instance;
    }

    public async fetchForProject(options: FetchOptions) {
        const {
            tenantId, projectId, keywords,
            topic, photographer, dryRun,
            dateFrom,
            dateUntil
        } = options;
        return await this.postRequest<GalleryImageResponse>(`/tenant/${tenantId}/project/${projectId}`, {
            keywords,
            topic,
            photographer,
            dateFrom,
            dateUntil,
            dryRun
        });
    }

    public async fetchImageFromPresignedUrl(presignedUrl: string): Promise<string> {
        return await fetch(presignedUrl)
            .then((response) => response.blob())
            .then((myBlob) => URL.createObjectURL(myBlob));
    }

    public async getImageUrl(bucketName: string, key: string, accessToken: string, size?: 150 | 300 | 1024 | 2000) {
        let url = `/getImage/${encodeURIComponent(bucketName)}/${encodeURIComponent(key)}?token=${accessToken}`;
        if (size) {
            url += `&size=${size}`;
        }
        const blob = await this.getBlobRequest<Blob>(url);

        return URL.createObjectURL(blob)

    }

    public async getImageUrlByAccessToken(options: { imageAccessToken: string, asThumbnail: boolean }): Promise<{
        presignedUrl: string
    }> {
        const {
            imageAccessToken,
            asThumbnail
        } = options;
        try {
            return await this.postRequest(`/getImage`, {
                imageAccessToken,
                asThumbnail
            });
        } catch (e) {
            if ((e as any).status === 503) {
                return await this.postRequest(`/getImage`, {
                    imageAccessToken,
                    asThumbnail
                }, true);
            }
            return undefined as any;
        }
    }

    public async downloadImages(requestedImages: DownloadImageRequest[]): Promise<any> {
        const {bucketName} = requestedImages[0];
        return await this.postRequest(`/downloadImages`, {images: requestedImages, bucketName});
    }

    public async deleteImages(images: GalleryImage[]) {
        return await this.postRequest<{ deletedImages: GalleryImage[] }>(`/deleteImages`, {images});
    }

    public async fetchImageStream(options: FetchOptions, retry?: boolean): Promise<Response> {
        try {
            const url = "https://o4zqa3kbgzprz2hgn4w6lu3v7i0kvfxo.lambda-url.eu-central-1.on.aws";
            return await fetch(url, {
                method: "POST",
                body: JSON.stringify({
                    ...options,
                    authorization: UserSession.getIdJwtToken()!
                }),
            })
        } catch (err) {
            if (AbstractApi.isErrorCode(err, 403)) {
                if (retry) {
                    throw err;
                }
                await UserSession.refreshToken();
                console.log("token was refreshed", "try again")
                return this.fetchImageStream(options, true);
            } else
                throw err
        }
    }
}
