import {Button, CircularProgress, TextField} from "@mui/material";
import {useFormik} from "formik";
import * as yup from "yup";
import {useUserSessionStore} from "../../../stores/UserSessionStore";
import {useCallback, useState} from "react";
import {enqueueSnackbar} from "notistack";
import {green} from "@mui/material/colors";
import {ChangeNameWrapper} from "./ChangeName.style";
import UserSession from "../../../api/UserSession";

interface ChangeNameProps {
}

const validationSchema = yup.object({
    name: yup
        // @ts-ignore
        .string('Vorname')
        .required('Vorname ist ein Pflichtfeld'),
    givenName: yup
        // @ts-ignore
        .string('Name')
        .required('Name ist ein Pflichtfeld'),
});

const ChangeName = (props: ChangeNameProps) => {
    const [isBusy, setBusy] = useState<boolean>(false);
    const {flatpicUser, updateNames} = useUserSessionStore();

    const handleOnSubmit = useCallback(async (names: { name: string, givenName: string }) => {
        if (await updateNames(names.name, names.givenName)) {
            await UserSession.refreshToken();
            enqueueSnackbar(`Dein Name wurde aktualisiert`, {
                variant: "success"
            });
        } else {
            enqueueSnackbar(`Fehler beim Aktualisieren deines Namens`, {
                variant: "error"
            });
        }
    }, [updateNames, enqueueSnackbar]);

    const formik = useFormik({
        initialValues: {
            name: flatpicUser?.name ?? "",
            givenName: flatpicUser?.givenName ?? "",
        },
        enableReinitialize: true,
        onSubmit: async (values) => {
            setBusy(true);
            handleOnSubmit({
                name: values.name || '',
                givenName: values.givenName || ''
            }).finally(() => setBusy(false));
        },
        validationSchema,
    });

    return <ChangeNameWrapper>
        <TextField label="Vorname"
                   id="name"
                   name="name"
                   fullWidth
                   variant="standard"
                   value={formik.values.name}
                   onChange={formik.handleChange}
                   error={formik.touched.name && Boolean(formik.errors.name)}
                   helperText={formik.touched.name && formik.errors.name}/>

        <TextField label="Nachname"
                   id="givenName"
                   name="givenName"
                   fullWidth
                   variant="standard"
                   value={formik.values.givenName}
                   onChange={formik.handleChange}
                   error={formik.touched.givenName && Boolean(formik.errors.givenName)}
                   helperText={formik.touched.givenName && formik.errors.givenName}/>


        <Button onClick={formik.handleSubmit as any}
                disabled={!formik.isValid || isBusy}
                color="success"
                variant="outlined"
                size="small">
            {isBusy && (
                <CircularProgress
                    size={24}
                    sx={{
                        color: green[500],
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        marginTop: '-12px',
                        marginLeft: '-12px',
                    }}/>
            )}
            Bestätigen
        </Button>
    </ChangeNameWrapper>
}

export default ChangeName;