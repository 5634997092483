import {FormControl} from "@mui/material";
import React, {useCallback} from "react";
import {DateTimePicker} from "@mui/x-date-pickers";
import moment from "moment";

interface DateTimeFilterProps {
    setValue: (value: number) => void;
    selectedValue: number;
    label: string;
}

const DateTimeFilter = (props: DateTimeFilterProps) => {
    const {label, selectedValue, setValue} = props;

    const setValueAsMillis = useCallback((value: number | null) => {
        const millis = value?.valueOf() ?? 0;
        setValue(millis);
    }, [setValue]);

    return <FormControl fullWidth
                        size="small"
                        variant="filled">
        <DateTimePicker label={label}
                        onChange={setValueAsMillis}
                        value={moment(selectedValue) as any}/>
    </FormControl>
}

export default DateTimeFilter;