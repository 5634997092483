import styled from "styled-components";

export const LogoCornerImage = styled.img.attrs(({src}) => ({
    src: src,
    alt: 'Flatpic Logo',
}))`
  cursor: pointer;
  position: absolute;
  top: 40px;
  left: 40px;
  height: 150px;
`;