import {ReactNode} from "react";
import {MenuRoute, MenuStructure} from "./MenuContainer";

export default function createMenu(label: string, to: string, element: ReactNode, icon?: ReactNode, subRoutes?: MenuRoute[]): MenuStructure {
    return {
        label,
        icon,
        route: {
            to,
            element,
            subRoutes
        }
    }
}