import {GlobalGalleryImageUploaderWrapper} from "./GlobalGalleryImageUploader.style";
import {FILE_ERROR, FileWithKeyword, useFileUploadStore} from "../../stores/FileUploadStore";
import {useCallback, useEffect} from "react";
import {useProjectsStore} from "../../stores/ProjectsStore";
import useFileValidator from "./useFileValidator";
import TenantApi from "../../api/TenantApi";
import PreSignedPostUrl from "../../domain/PreSignedPostUrl";
import axios, {AxiosError} from "axios";
import {useUserSessionStore} from "../../stores/UserSessionStore";
import {LinearProgress} from "@mui/material";
import withSelectedProject from "../withSelectedProject/withSelectedProject";
import {enqueueSnackbar} from "notistack";

interface GlobalGalleryImageUploaderProps {
}

function isAxiosError(value: any): value is AxiosError {
    return value.name === AxiosError.name;
}

const MAX_SIMULTANEOUSLY_UPLOADS: number = 4;

const GlobalGalleryImageUploader = (props: GlobalGalleryImageUploaderProps) => {
    const {
        pendingFiles,
        uploadingFiles,
        uploadedFiles,
        addFileToUploading,
        changeProgress,
        uploadComplete,
        uploadFailed,
        cancelFailed,
        currentUploadSize,
    } = useFileUploadStore();
    const {flatpicUser} = useUserSessionStore();
    const {activeProject} = useProjectsStore();
    const {validateFile} = useFileValidator();
    const userName = flatpicUser?.name! + flatpicUser?.givenName;

    const createFormData = useCallback((file: File, url: PreSignedPostUrl) => {
        const formData = new FormData();
        formData.append("Content-Type", file.type);
        Object.entries(url.fields).forEach(([k, v]) => {
            formData.append(k, v);
        });
        formData.append("userid", flatpicUser?.userId ?? '');
        formData.append("file", file);
        return formData;
    }, [flatpicUser]);

    const determineUploadFileName = useCallback((fileName: string, topicKeyword: string) => {
        try {
            const fileNameParts = fileName.split("_");
            const timestamp = fileNameParts[fileNameParts.length - 1].split(".")[0];
            const upperTopicKeyword = topicKeyword.split("_")
                .map(part => part[0].toUpperCase() + part.substring(1))
                .join("_");

            return `EWF22_${upperTopicKeyword}_${userName}_${timestamp}`;
        } catch (e) {
            console.log("error", fileName, topicKeyword)
            throw e;
        }
    }, [userName]);

    const startUploadProcess = useCallback(async (file: FileWithKeyword) => {
        if (!activeProject) {
            return;
        }

        const {errors, topicKeyword} = await validateFile(file);
        file.topicKeyword = topicKeyword;

        if (errors.length) {
            if (errors.indexOf(FILE_ERROR.NO_IMAGE_FILE) > -1) {
                enqueueSnackbar(`Ungültige Datei`, {
                    variant: "error"
                });
                cancelFailed(file.file);
                return;
            }
            uploadFailed(file, errors);
            return;
        }

        addFileToUploading(file);
        const uploadFileName = determineUploadFileName(file.file.name, topicKeyword);
        const url = await TenantApi.INSTANCE.createPostImageUrl(activeProject.tenantId,
            activeProject.projectId,
            file.file.size,
            topicKeyword,
            uploadFileName);
        const formData = createFormData(file.file, url);
        try {
            await axios.post(url.url, formData, {
                onUploadProgress: progressEvent => {
                    changeProgress(file, progressEvent.progress ?? 0);
                }
            });
            uploadComplete(file);
        } catch (e: unknown) {
            if (isAxiosError(e)) {
                const data = JSON.stringify(e.response?.data ?? '');
                if (data.indexOf('EntityTooLarge')) {
                    uploadFailed(file, [FILE_ERROR.FILE_TO_BIG]);
                }
            } else
                console.error("error upload", e);
        }

    }, [addFileToUploading, activeProject, validateFile, determineUploadFileName,
        uploadFailed, createFormData, uploadComplete, changeProgress])

    useEffect(() => {
        if (!activeProject) {
            return;
        }
        if (pendingFiles.length > 0 && uploadingFiles.length < MAX_SIMULTANEOUSLY_UPLOADS) {
            const file = pendingFiles.shift();
            if (!file) {
                return;
            }
            startUploadProcess(file);
        }
    }, [pendingFiles, uploadingFiles, uploadedFiles, startUploadProcess, activeProject]);

    const renderProgress = useCallback(() => {
        const currentUploading = currentUploadSize;
        if (currentUploading === 0) {
            return <></>
        }
        const pending = pendingFiles.length;
        const uploading = uploadingFiles.length;
        const complete = currentUploading - uploading - pending;

        const value = complete / currentUploading * 100;
        const buffer = (uploading / currentUploading * 100) + value;
        return <LinearProgress variant="buffer" value={value} valueBuffer={buffer}/>
    }, [pendingFiles, uploadingFiles, currentUploadSize]);

    return <GlobalGalleryImageUploaderWrapper>
        {renderProgress()}
    </GlobalGalleryImageUploaderWrapper>
}

export default withSelectedProject(GlobalGalleryImageUploader);