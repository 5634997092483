import {LibraryFilterWrapper} from "./LibraryFilter.style";
import PhotographerFilter from "./PhotographerFilter";
import TopicFilter from "./TopicFilter";
import KeywordsFilter from "./KeywordsFilter";
import {Heading} from "../LibraryPage.style";
import DateTimeFromFilter from "./DateTimeFromFilter";
import DateTimeUntilFilter from "./DateTimeUntilFilter";

interface LibraryFilterProps {
}

const LibraryFilter = (props: LibraryFilterProps) => {

    return <LibraryFilterWrapper>
        <Heading>Filter</Heading>

        <TopicFilter/>
        <PhotographerFilter/>
        <DateTimeFromFilter/>
        <DateTimeUntilFilter/>
        <KeywordsFilter/>
    </LibraryFilterWrapper>
}

export default LibraryFilter;