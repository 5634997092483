import styled from "styled-components";

interface GalleryWrapperProps {
    size: number;
    gap?: number;
    backgroundColor?: string;
}

export const LoadingInfo = styled.div`
  text-align: center;
  margin: auto;
  width: 300px;

  h3 {
    font-weight: 100;
  }
`;

export const GalleryWrapper = styled.div.attrs<GalleryWrapperProps>(props => ({
    style: {
        gridTemplateColumns: 'repeat(auto-fit, ' + props.size + 'px)',
        gridAutoRows: determineRowHeight(props.size) + 'px',
        gap: (props.gap || 25) + 'px'
    }
}))`
  display: grid;
  justify-content: center;
  margin: auto;
  width: 100%;
  background-color: ${(p: GalleryWrapperProps) => p.backgroundColor || 'inherit'};
`;

function determineRowHeight(width:number):number {
    return width / 3 * 2;
}