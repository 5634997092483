import styled from "styled-components";

export const SideMenuScrollContainerWrapper = styled.div`
`;
export const SideMenuScrollContainerStyled = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
`;