import {create} from "zustand";
import Tenant from "../domain/Tenant";
import UserApi from "../api/UserApi";
import {determineStorageValue} from "./determineStorageValue";

export type TenantStore = {
    tenants: Tenant[];
    selectedTenant: Tenant | undefined;
    fetchTenantsForUser: (userId: string) => Promise<Tenant[]>;
    selectTenant: (tenant: Tenant) => void;
    unselectTenant: () => void;
};
const SELECTED_TENANT_STORAGE_KEY: string = "SELECTED_TENANT_STORAGE_KEY";

export const useTenantStore = create<TenantStore>()((set, get) => ({
    tenants: [],
    selectedTenant: determineStorageValue<Tenant>(SELECTED_TENANT_STORAGE_KEY),
    fetchTenantsForUser: async (userId: string) => {
        const tenants = await UserApi.INSTANCE.fetchTenantsForUser(userId);
        set((state) =>
            ({...state, tenants}));
        return tenants;
    },
    selectTenant: (tenant: Tenant) => {
        set((state) =>
            ({...state, selectedTenant: tenant}));
        localStorage.setItem(SELECTED_TENANT_STORAGE_KEY, JSON.stringify(tenant));
    },
    unselectTenant: () => {
        set((state) =>
            ({...state, selectedTenant: undefined}));
        localStorage.removeItem(SELECTED_TENANT_STORAGE_KEY);
    }
}));

