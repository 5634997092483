import styled from "styled-components";

export const ListChipFilterWrapper = styled.div`
  width: 100%;
`;

export const ChipWrapper = styled.div`
  display: flex;
  gap: 5px;
  flex-wrap: wrap;
`;

export const Headline = styled.h3`
  font-size: 15px;
  font-weight: normal;
`;

export const OptionCount = styled.span`
  margin-right: 6px !important;
  font-size: 11px !important;
`;