import {API} from "aws-amplify";
import UserSession from "./UserSession";


export default abstract class AbstractApi {
    private static APIS: Array<{ name: string, endpoint: string }> = [];
    private readonly apiEndpoint: string;

    protected constructor(private readonly apiName: string, apiEndpoint: string | undefined) {
        this.checkApiEndpointIsNotNull(apiEndpoint);
        this.apiEndpoint = apiEndpoint as string;
        this.addApi()
        this.configureApi();
    }

    private addApi(): void {
        if (AbstractApi.APIS.filter(api => api.name === this.apiName).length === 0) {
            AbstractApi.APIS.push({
                name: this.apiName,
                endpoint: this.apiEndpoint ?? ''
            })
        }
    }

    private configureApi(): void {
        API.configure({
            endpoints: AbstractApi.APIS
        });
    }

    private getHeaders() {
        const idJwtToken = UserSession.getIdJwtToken();
        return idJwtToken ? {
            Authorization: `Bearer ${idJwtToken}`
        } : {};
    }

    // @ts-ignore
    protected async getBlobRequest<T>(path: string, retry?: boolean): Promise<T> {
        try {
            return await API.get(this.apiName, path, {
                headers: this.getHeaders(),
                responseType: 'blob',
            });
        } catch (err) {
            console.log("err", err)
            if (AbstractApi.isErrorCode(err, 403)) {
                if (retry) {
                    throw err;
                }
                await UserSession.refreshToken();
                return this.getBlobRequest(path, true);
            }
        }
    }

    // @ts-ignore
    protected async getRequest<T>(path: string, retry?: boolean): Promise<T> {
        try {
            if (retry) {
                console.log("second try", this.apiName, path)
            }
            return await API.get(this.apiName, path, {
                headers: this.getHeaders()
            });
        } catch (err) {
            if (AbstractApi.isErrorCode(err, 403)) {
                if (retry) {
                    throw err;
                }
                await UserSession.refreshToken();
                console.log("token was refreshed", "try again")
                return this.getRequest(path, true);
            }
        }
    }

    protected determineEndpointUrl() {
        return API.endpoint(this.apiName);
    }

    // @ts-ignore
    protected async postRequest<T>(path: string, body: object, headers?: any, retry?: boolean): Promise<T> {
        try {
            if (retry) {
                console.log("second try", this.apiName, path)
            }
            return await API.post(this.apiName, path, {
                headers: {
                    ...this.getHeaders(),
                    ...headers
                },
                body: body
            });
        } catch (err) {
            if (AbstractApi.isErrorCode(err, 403)) {
                if (retry) {
                    throw err;
                }
                await UserSession.refreshToken();
                console.log("token was refreshed", "try again")
                return this.postRequest<T>(path, body, headers, true);
            } else
                throw err
        }
    };

    protected async putRequest<T>(path: string, body: object): Promise<T> {
        try {
            return await API.put(this.apiName, path, {
                headers: this.getHeaders(),
                body: body
            });
        } catch (err) {
            throw err;
        }
    };

    protected async deleteRequest<T>(path: string): Promise<T> {
        try {
            return await API.del(this.apiName, path, {
                headers: this.getHeaders()
            });
        } catch (err) {
            throw err;
        }
    };

    private static isResponseError(error: any): error is { response: { status: number } } {
        return error?.response?.status;
    }

    protected static isErrorCode(error: any, code: number): boolean {
        if (!AbstractApi.isResponseError(error)) {
            return false;
        }
        return error?.response?.status === code;
    }

    private checkApiEndpointIsNotNull(apiEndpoint: string | undefined) {
        if (!apiEndpoint) {
            throw new Error(`API ENDPOINT was not set for ${this.apiName}`);
        }
    }
}