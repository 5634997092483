import styled from "styled-components";


interface TileBackgroundProps {
    backgroundUrl: string;

}

export const TileBackground = styled.div`
  background-image: url(${(props: TileBackgroundProps) => props.backgroundUrl});
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  filter: blur(18px);
  opacity: 0.6;
  position: absolute;
  left: 0;
  right: 0;
  z-index: 1;
  display: block;
  width: 100%;
  height: 100%;
`;

export const ImageContainer = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  z-index: 1;
  width: 100%;
  height: 100%;

  img {
    object-fit: scale-down;
    width: 100%;
    height: 100%;
    user-select: none;
    -webkit-user-drag: none;
    -moz-user-drag: none;
    -ms-user-drag: none;
  }
`;
export const ImageWrapper = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 5px;
  transition: all 0.2s;
`;

export const ImageTileWrapper = styled.div`
  position: relative;
  border-radius: 5px;
  background-color: #fff;
  overflow: hidden;
  cursor: pointer;
  border:1px solid #a9a9a9;
  box-shadow: none;

  transition: all 0.2s;
  opacity: 0;

  &.fadeIn {
    opacity: 1;
  }

  &.selected {
    //box-shadow: 0 0 20px -10px #000000b3;
    ${ImageWrapper} {
      scale: 0.8;
    }

    transition: all 0.2s;
  }

`;
