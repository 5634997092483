import ExifReader from "exifreader";
import {FileWithKeyword} from "../../stores/FileUploadStore";

export default function useMetaDataExtractor() {
    const extractMetaData = async (file: FileWithKeyword) => {
        const meta = await ExifReader.load(file.file);
        const keywords = file.topicKeyword ? [file.topicKeyword] : extractKeywords(meta.Keywords);
        const dateTimeOriginal = meta.DateTimeOriginal?.description;
        const focalLength = meta.FocalLengthIn35mmFilm?.description;
        const copyright = meta.Copyright?.description;
        return {
            keywords,
            dateTimeOriginal,
            focalLength,
            copyright
        }
    }

    const extractKeywords = (keywords: Array<any> | any): Array<string> | undefined => {
        if (keywords == undefined) {
            return [];
        }
        if (Array.isArray(keywords)) {
            return (keywords as any)?.map((keyword: any) => keyword.description);
        }
        return [keywords.description];
    }

    return {extractMetaData};
}