import AbstractApi from "./AbstractApi";
import Tenant from "../domain/Tenant";
import Project from "../domain/Project";

export default class UserApi extends AbstractApi {
    public static _instance: UserApi;

    private constructor() {
        super("User", process.env.REACT_APP_USER_API);
    }

    public static get INSTANCE(): UserApi {
        if (!this._instance) {
            this._instance = new UserApi()
        }
        return this._instance;
    }

    public async fetchTenantsForUser(userId: string) {
        const result = await this.getRequest<Tenant[]>(`/${userId}/tenants`);
        return result as any as Tenant[];
    }

    public async fetchAllProjects(userId: string) {
        const result = await this.getRequest<Project[]>(`/${userId}/projects`);
        return result as any as Project[];
    }

    public async updateNames(userId: string, name: string, givenName: string) {
        const result = await this.putRequest(`/${userId}/names`, {name, givenName});
        return result as any as void;
    }

    public async downloadKeywordFile(userId: string, tenantId: string, projectId: string) {
        return this.getRequest<{
            keywords: string
        }>(`/${userId}/tenant/${tenantId}/project/${projectId}/downloadkeywords`)
    }
}