import React from 'react';
import {LoginPageWrapper} from "./LoginPage.style";
import LoginForm from "./LoginForm/LoginForm";
import {NEW_PASSWORD_REQUIRED, useUserSessionStore} from "../../../stores/UserSessionStore";
import {useNavigate} from "react-router-dom";
import FlatpicBox from "../../../components/FlatpicBox/FlatpicBox";
import LogoCorner from "../../../components/LogoCorner/LogoCorner";
import withNoAuthentication from "../../../components/withNoAuthentication/withNoAuthentication";

interface LoginPageProps {
}

const LoginPage = (props: LoginPageProps) => {
    const {login} = useUserSessionStore();
    const navigate = useNavigate();

    const handleLogin = async (username: string, password: string): Promise<string[]> => {
        const result = await login(username, password);
        if (!result.length) {
            navigate("/");
        } else {
            if (result.includes(NEW_PASSWORD_REQUIRED)) {
                navigate("/new-password-required");
            }
            //todo fehlerausgabe in der UI
            console.warn(result)
        }
        return [];
    }

    return <LoginPageWrapper>
        <LogoCorner/>
        <FlatpicBox>
            <LoginForm doLogin={handleLogin}/>
        </FlatpicBox>
    </LoginPageWrapper>;
};

export default withNoAuthentication(LoginPage);