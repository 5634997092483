import styled from "styled-components";

export const PageHeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 30px;
  margin-bottom: 30px;
`;

export const IconContainer = styled.div`

`;

export const LeftButtonContainer = styled.div`
  display: flex;
  gap:20px;
  align-items: center;
`;

export const RightButtonContainer = styled.div`
  display: flex;
  gap:20px;
  justify-content: flex-end;
`;

export const ContextButtonContainer = styled.div`

`;

export const TitleContainer = styled.span`
  font-size: 45px;
`;