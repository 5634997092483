import {ComponentType, useCallback, useEffect, useState} from 'react';
import {useUserSessionStore} from "../../stores/UserSessionStore";
import {useLocation, useNavigate} from "react-router-dom";
import {FLATPIC_USER_ROLE} from "../../domain/FlatpicUser";
import {useTenantStore} from "../../stores/TenantStore";

interface WithAuthenticationProps {
}

export default function withUserRoleContext(WrappedComponent: ComponentType,
                                            AlternativeComponent?: ComponentType) {
    return function (_props: WithAuthenticationProps) {
        const {flatpicUser, userRole, unselectUserRole} = useUserSessionStore();
        const {fetchTenantsForUser} = useTenantStore();
        const navigate = useNavigate();
        const location = useLocation()
        const [isDeterminingUserRole, setIsDeterminingUserRole] = useState<boolean>(true);

        const forwardTo = useCallback((path: string) => {
            setTimeout(() =>
                navigate(path, {state: {prevPath: location.pathname}}), 0);
        }, [navigate]);

        const forwardToRoleSelection = useCallback(() => {
            forwardTo("/select-role");
        }, [forwardTo]);

        const hasTenantAccess = useCallback(async () => {
            if (!flatpicUser) {
                throw Error("no flatpic user was found.");
            }
            const tenants = await fetchTenantsForUser(flatpicUser.userId);
            return tenants.length > 0;
        }, [flatpicUser, fetchTenantsForUser]);

        const determineUserRole = useCallback(async () => {
            switch (userRole) {
                case FLATPIC_USER_ROLE.TENANT:
                    if (!await hasTenantAccess()) {
                        unselectUserRole();
                        forwardToRoleSelection();
                    }
                    break;
                case FLATPIC_USER_ROLE.CREW:
                    break;
                default:
                    unselectUserRole();
                    forwardToRoleSelection();
                    break;
            }
            setIsDeterminingUserRole(false);
        }, [userRole, hasTenantAccess, unselectUserRole, forwardToRoleSelection, setIsDeterminingUserRole]);


        useEffect(() => {
            if (!flatpicUser) {
                return;
            }
            determineUserRole();
        }, [flatpicUser, determineUserRole]);


        const render = () => {
            if (isDeterminingUserRole) {
                return <></>;
            }
            return <WrappedComponent/>;
        }

        return render();
    };
}
