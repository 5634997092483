export function determineStorageValue<T>(key: string): undefined | T {
    const item = localStorage.getItem(key);
    if (item === null) {
        return undefined;
    }
    return JSON.parse(item);
}

export function determineStorageValueWithFallback<T>(key: string, fallbackValue: T): T {
    const item = localStorage.getItem(key);
    if (item === null) {
        return fallbackValue;
    }
    return JSON.parse(item);
}