import {SelectUserRolePageWrapper} from "./SelectUserRolePage.style";
import LogoCorner from "../../components/LogoCorner/LogoCorner";
import FlatpicBox from "../../components/FlatpicBox/FlatpicBox";
import Diversity1Icon from '@mui/icons-material/Diversity1';
import BadgeIcon from '@mui/icons-material/Badge';
import {useLocation, useNavigate} from "react-router-dom"
import React, {useCallback, useEffect, useState} from "react";
import {useTenantStore} from "../../stores/TenantStore";
import {useUserSessionStore} from "../../stores/UserSessionStore";
import {Box, CircularProgress, Divider, List, ListItemButton, ListItemIcon, ListItemText} from "@mui/material";
import {FLATPIC_USER_ROLE} from "../../domain/FlatpicUser";
import Tenant from "../../domain/Tenant";

interface SelectUserRolePageProps {
}

const SelectUserRolePage = (props: SelectUserRolePageProps) => {
    const {flatpicUser, setUserRole, fetchCurrentSession} = useUserSessionStore();
    const {tenants, selectTenant, fetchTenantsForUser} = useTenantStore();
    const navigate = useNavigate();
    const location = useLocation();
    const headline = !location.state?.hideCrewOption ? "Wähle deine Rolle" : "Wähle deinen Mandanten";
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const handleListItemClick = useCallback((role: FLATPIC_USER_ROLE, tenant?: Tenant) => {
        setUserRole(role);
        if (tenant) {
            selectTenant(tenant);
        }
        navigate(location.state?.prevPath ?? '/');
    }, [setUserRole, selectTenant, navigate, location]);

    const fetchTenants = useCallback(async () => {
        setIsLoading(true);
        const tenants = await fetchTenantsForUser(flatpicUser?.userId ?? "");
        setIsLoading(false)
    }, [fetchTenantsForUser, flatpicUser]);

    useEffect(() => {
        if (!flatpicUser) {
            fetchCurrentSession();
            return;
        }
        if (tenants.length === 0) {
            fetchTenants();
        }
    }, [flatpicUser, fetchCurrentSession, fetchTenants]);

    return <SelectUserRolePageWrapper>
        <LogoCorner/>
        <FlatpicBox headline={headline}>
            <Box sx={{width: '100%', maxWidth: 360, bgcolor: 'background.paper'}}>
                {isLoading &&
                    <CircularProgress color="secondary"></CircularProgress>}
                {!isLoading && <List component="nav">
                    {!location.state?.hideCrewOption && <ListItemButton
                        onClick={(event) => handleListItemClick(FLATPIC_USER_ROLE.CREW)}>
                        <ListItemIcon>
                            <Diversity1Icon/>
                        </ListItemIcon>
                        <ListItemText primary="Crew mitglied"/>
                    </ListItemButton>}
                    {tenants?.length > 0 && <Divider/>}
                    {tenants?.map(tenant =>
                        <ListItemButton
                            key={tenant.tenantId}
                            onClick={(event) => handleListItemClick(FLATPIC_USER_ROLE.TENANT, tenant)}>
                            <ListItemIcon>
                                <BadgeIcon/>
                            </ListItemIcon>
                            <ListItemText primary={tenant.name}/>
                        </ListItemButton>
                    )}
                </List>}
            </Box>
        </FlatpicBox>
    </SelectUserRolePageWrapper>
}

export default SelectUserRolePage;