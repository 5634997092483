import {SelectProjectPageWrapper} from "./SelectProjectPage.style";
import {useUserSessionStore} from "../../stores/UserSessionStore";
import {useTenantStore} from "../../stores/TenantStore";
import {useLocation, useNavigate} from "react-router-dom";
import React, {useCallback, useEffect, useState} from "react";
import {FLATPIC_USER_ROLE} from "../../domain/FlatpicUser";
import LogoCorner from "../../components/LogoCorner/LogoCorner";
import FlatpicBox from "../../components/FlatpicBox/FlatpicBox";
import {Box, CircularProgress, List, ListItemButton, ListItemIcon, ListItemText, Skeleton} from "@mui/material";
import BadgeIcon from "@mui/icons-material/Badge";
import {useProjectsStore} from "../../stores/ProjectsStore";
import Project from "../../domain/Project";

interface SelectProjectPageProps {
}

const SelectProjectPage = (props: SelectProjectPageProps) => {
    const {flatpicUser, userRole, fetchCurrentSession} = useUserSessionStore();
    const {tenants, selectTenant, selectedTenant} = useTenantStore();
    const {projects, fetchAllProjectsForCrew, fetchAllProjectsForTenant, activateProject} = useProjectsStore();
    const navigate = useNavigate();
    const location = useLocation();
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const handleListItemClick = useCallback((project: Project) => {
        if (userRole == FLATPIC_USER_ROLE.CREW) {
            selectTenant({tenantId: project.tenantId});
        }
        activateProject(project.projectId);
        navigate(location.state?.prevPath ?? '/');
    }, []);

    const fetchProjects = useCallback(async () => {
        setIsLoading(true);
        switch (userRole) {
            case FLATPIC_USER_ROLE.TENANT:
                await fetchAllProjectsForTenant(selectedTenant?.tenantId ?? "");
                break;
            case FLATPIC_USER_ROLE.CREW:
                await fetchAllProjectsForCrew(flatpicUser?.userId ?? "");
                break;
        }
        setIsLoading(false);
    }, [userRole, flatpicUser, selectedTenant, fetchAllProjectsForTenant, fetchAllProjectsForCrew]);

    useEffect(() => {
        if (!flatpicUser) {
            fetchCurrentSession();
            return;
        }
        if (!userRole) {
            return;
        }
        if (userRole == FLATPIC_USER_ROLE.TENANT && !selectedTenant) {
            return;
        }
        fetchProjects();
    }, [flatpicUser, userRole, fetchProjects, selectedTenant]);

    return <SelectProjectPageWrapper><LogoCorner/>
        <FlatpicBox headline="Wähle ein Projekt">
            <Box sx={{width: '100%', maxWidth: 360, bgcolor: 'background.paper'}}>
                {isLoading &&
                    <><CircularProgress color="secondary"/></>}
                {!isLoading && <List component="nav">
                    {projects.map(project =>
                        <ListItemButton
                            key={project.projectId}
                            onClick={(event) => handleListItemClick(project)}>
                            <ListItemIcon>
                                <BadgeIcon/>
                            </ListItemIcon>
                            <ListItemText primary={project.name}/>
                        </ListItemButton>
                    )}
                </List>}
            </Box>
        </FlatpicBox>
    </SelectProjectPageWrapper>
}

export default SelectProjectPage;