import MenuContainer from "./shared/MenuContainer";
import createMenu from "./shared/createMenu";
import {lazy} from "react";
import TopicIcon from '@mui/icons-material/Topic';
import LibraryMusicOutlinedIcon from '@mui/icons-material/LibraryMusicOutlined';
import Diversity3OutlinedIcon from '@mui/icons-material/Diversity3Outlined';
import {FLATPIC_USER_ROLE} from "./domain/FlatpicUser";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

const TopicAdministrationPage = lazy(() => import( "./pages/TopicAdministrationPage/TopicAdministrationPage"));
const ImageUploadPage = lazy(() => import("./pages/ImageUploadPage/ImageUploadPage"));
const CrewAdministrationPage = lazy(() => import( "./pages/CrewAdministrationPage/CrewAdministrationPage"));
const ProjectAdministrationPage = lazy(() => import("./pages/ProjectAdministrationPage/ProjectAdministrationPage"));

const FlatpicMenus: MenuContainer[] = [
    createProjectMenu(),
    createConfiguration(),
    createUploadMenu()
];

function createConfiguration(): MenuContainer {
    return {
        id: "settings",
        name: "Einstellungen",
        neededUserRole: FLATPIC_USER_ROLE.TENANT,
        menus: [
            createMenu("Projekte", '/projects', <ProjectAdministrationPage/>, <LibraryMusicOutlinedIcon/>),
        ]
    };
}

function createUploadMenu(): MenuContainer {
    return {
        id: "upload",
        name: "Bibliothek",
        menus: [
            createMenu("Upload", "/upload", <ImageUploadPage/>, <CloudUploadIcon/>)
        ]
    }
}

function createProjectMenu(): MenuContainer {
    return {
        id: "projectsettings",
        name: "#activeProjektName#",
        neededUserRole: FLATPIC_USER_ROLE.TENANT,
        menus: [
            createMenu("Themen", '/topics', <TopicAdministrationPage/>, <TopicIcon/>),
            createMenu("Crew", '/crew', <CrewAdministrationPage/>, <Diversity3OutlinedIcon/>),
        ]
    };
}

export default FlatpicMenus;