import React, {RefObject, useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {ImageContainer, ImageTileWrapper, ImageWrapper, TileBackground} from "./ImageTile.style";
import {useGalleryStore} from "../../../stores/GalleryStore";
import GalleryImage from "../../../domain/Gallery/GalleryImage";
import ImageUrlDeterminer from "../../../domain/Gallery/ImageUrlDeterminer";
import {Skeleton} from "@mui/material";

interface ImageTileProps {
    image: GalleryImage
    selected: boolean;
    size?: number;
    imageSize: number;
}

function useOnScreen(ref: RefObject<HTMLElement>) {
    const [isIntersecting, setIntersecting] = useState(false)

    const observer = useMemo(() => new IntersectionObserver(
        ([entry]) => setIntersecting(entry.isIntersecting)
    ), [ref])

    useEffect(() => {
        if (ref.current)
            observer.observe(ref.current)
        return () => observer.disconnect()
    }, [ref])

    return isIntersecting
}

const ImageTile = (props: ImageTileProps) => {
    const [isLoaded, setIsLoaded] = useState<boolean>(true);
    const {selectImage, unselectImage} = useGalleryStore();
    const [url, setUrl] = useState<string>("");
    const [backgroundUrl, setBackgroundUrl] = useState<string>("");
    const {image, selected, imageSize} = props;
    const {key} = image;

    const ref = useRef<HTMLDivElement>(null)
    const isVisible = useOnScreen(ref)
    const selectedClass = selected ? 'selected' : '';
    const isLoadedClass = isLoaded ? 'fadeIn' : '';
    const classNames = [selectedClass, isLoadedClass].join(' ');

    const handleClick = () => {
        if (selected) {
            unselectImage(image);
        } else {
            selectImage(image);
        }
    }

    // nach ändern des images muss die url entfernt werden um das neuladen der url für das neue image zu initialisieren
    useEffect(() => {
        setUrl("")
        setBackgroundUrl("")
    }, [setUrl, key, imageSize])

    const loadImageUrl = useCallback(async () => {
        const urlResult = ImageUrlDeterminer.determineUrl(image, imageSize);

        if (urlResult instanceof Promise) {
            setUrl(await urlResult);
            return;
        }
        setUrl(urlResult as string);
    }, [setUrl, setIsLoaded, image, imageSize])

    const loadBackgroundImageUrl = useCallback(async () => {
        const urlResult = ImageUrlDeterminer.determineUrl(image, 150);

        if (urlResult instanceof Promise) {
            setBackgroundUrl(await urlResult);
            return;
        }
        setBackgroundUrl(urlResult as string);
    }, [setBackgroundUrl, image])

    useEffect(() => {
        if (isVisible && !url) {
             loadImageUrl();
            // loadBackgroundImageUrl();

        }
    }, [isVisible, url]);

    return <ImageTileWrapper onClick={handleClick}
                             ref={ref}
                             style={{
                                 height: props.size + "px",
                                 width: props.size + "px"
                             }}
                             className={classNames}>
        {!url && <Skeleton variant="rectangular"
                           height="100%"
                           animation="pulse">
        </Skeleton>}

        {url &&
            <ImageWrapper>
                <TileBackground backgroundUrl={image.presignedUrl150}/>
                <ImageContainer>
                    <img alt="bild"
                         src={url}
                         loading="lazy"/>
                </ImageContainer>
            </ImageWrapper>
        }
    </ImageTileWrapper>;
};

export default ImageTile;