import React from 'react';
import {NoMatchPageWrapper} from "./NoMatchPage.style";

interface NoMatchProps {
}

const NoMatchPage = (_props: NoMatchProps) => {
    return <NoMatchPageWrapper>
        <h1>
            Keine Seite gefunden :-(
        </h1>
    </NoMatchPageWrapper>;
};

export default NoMatchPage;