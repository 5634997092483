import React from 'react';
import {FlatpicFooterWrapper} from "./FlatpicFooter.style";

interface FlatpicFooterProps {
}

const FlatpicFooter = (props: FlatpicFooterProps) => {
    return <FlatpicFooterWrapper>
        © 2023, Kästner Notzon GbR
    </FlatpicFooterWrapper>;
};

export default FlatpicFooter;