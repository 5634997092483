import {create} from "zustand";
import {determineStorageValueWithFallback} from "./determineStorageValue";
import GalleryApi from "../api/GalleryApi";

export interface LibraryFilterOption {
    value: string;
    label: string;
    count: number;
}

interface LibraryFilterOptions {
    photographer: LibraryFilterOption[];
    sources: LibraryFilterOption[];
    topics: LibraryFilterOption[];
    keywords: LibraryFilterOption[];
}

export type LibraryFilterStore = {
    setOptions: (options: LibraryFilterOptions) => void,
    clearSelections: () => void,
    unselect: (filterName: keyof LibraryFilterOptions) => void,
    fetchOptionsForProject: (tenantId: string, projectId: string) => Promise<void>,
    setOptionCounts: (options: LibraryFilterOptions) => void,
    sources: LibraryFilterOption[];
    selectedSource: string;
    fetchSources: () => Promise<void>;
    setSource: (source: string) => void;
    photographer: LibraryFilterOption[];
    selectedPhotographer: string;
    setPhotographer: (userId: string) => void;
    topics: LibraryFilterOption[];
    selectedTopic: string;
    setTopic: (topicId: string) => void;
    keywords: LibraryFilterOption[];
    selectedKeywords: string[];
    setKeywords: (keywords: string[]) => void;
    addKeyword: (keyword: string) => void;
    removeKeyword: (keyword: string) => void;
    selectedDateTimeFrom: number;
    setDateTimeFrom: (dateTime: number) => void;
    selectedDateTimeUntil: number;
    setDateTimeUntil: (dateTime: number) => void;
}

const SELECTED_DATE_TIME_FROM_STORAGE_KEY: string = "SELECTED_DATE_TIME_FROM_STORAGE_KEY";
const SELECTED_DATE_TIME_UNTIL_STORAGE_KEY: string = "SELECTED_DATE_TIME_UNTIL_STORAGE_KEY";
const SELECTED_SOURCE_STORAGE_KEY: string = "SELECTED_SOURCE_STORAGE_KEY";
const SELECTED_PHOTOGRAPHER_STORAGE_KEY: string = "SELECTED_PHOTOGRAPHER_STORAGE_KEY";
const SELECTED_TOPIC_STORAGE_KEY: string = "SELECTED_TOPIC_STORAGE_KEY";
const SELECTED_KEYWORDS_STORAGE_KEY: string = "SELECTED_KEYWORDS_STORAGE_KEY";
export const UNSELECTED_KEY = "";
export const useLibraryFilterStore = create<LibraryFilterStore>()((set, get) => ({
    setOptions: (options) => {
        set(state => ({
            ...state,
            ...options
        }));
    },
    clearSelections: () => {
        set(state => ({
            ...state,
            selectedKeywords: [],
            selectedTopic: UNSELECTED_KEY,
            selectedSource: UNSELECTED_KEY,
            selectedPhotographer: UNSELECTED_KEY
        }))
    },
    unselect: (filterName: keyof LibraryFilterOptions) => {
        set(state => {
            const newState = {...state};
            (newState[filterName] as any) = undefined;
            return newState;
        });
    },
    fetchOptionsForProject: async (tenantId: string, projectId: string) => {
        const galleryImageResponse = await GalleryApi.INSTANCE.fetchForProject({tenantId, projectId, dryRun: true});
        get().setOptions(galleryImageResponse.aggregation as any);
    },
    setOptionCounts: (options) => {
        set(state => ({
            ...state,
            photographer: updateOptionCounts(state.photographer, options.photographer),
            keywords: updateOptionCounts(state.keywords, options.keywords),
            topics: updateOptionCounts(state.topics, options.topics),
        }))
    },
    sources: [],
    selectedSource: determineStorageValueWithFallback(SELECTED_SOURCE_STORAGE_KEY, "library"),
    setSource: (source: string) => {
        setLocalStorageItem(SELECTED_SOURCE_STORAGE_KEY, source);
        set(state => ({...state, selectedSource: source}))
    },
    fetchSources: () => {
        return new Promise<void>(resolve => resolve());
    },
    photographer: [],
    selectedPhotographer: determineStorageValueWithFallback(SELECTED_PHOTOGRAPHER_STORAGE_KEY, UNSELECTED_KEY),
    setPhotographer: (userId: string) => {
        setLocalStorageItem(SELECTED_PHOTOGRAPHER_STORAGE_KEY, userId);
        set(state => ({...state, selectedPhotographer: userId}))
    },
    topics: [],
    selectedTopic: determineStorageValueWithFallback(SELECTED_TOPIC_STORAGE_KEY, UNSELECTED_KEY),
    setTopic: (topicId: string) => {
        setLocalStorageItem(SELECTED_TOPIC_STORAGE_KEY, topicId);
        set(state => ({...state, selectedTopic: topicId}))
    },
    keywords: [],
    selectedKeywords: determineStorageValueWithFallback(SELECTED_KEYWORDS_STORAGE_KEY, []),
    setKeywords: (keywords: string[]) => {
        setLocalStorageItem(SELECTED_KEYWORDS_STORAGE_KEY, keywords);
        set(state => ({...state, selectedKeywords: keywords}));
    },
    addKeyword: (keyword: string) => {
        set(state => ({...state, selectedKeywords: [...state.selectedKeywords, keyword]}));
        setLocalStorageItem(SELECTED_KEYWORDS_STORAGE_KEY, get().selectedKeywords);
    },
    removeKeyword: (keyword: string) => {
        set(state => {
            const index = state.selectedKeywords.indexOf(keyword);
            const selectedKeywords = state.selectedKeywords;
            if (index >= 0) {
                selectedKeywords.splice(index, 1);
            }

            setLocalStorageItem(SELECTED_KEYWORDS_STORAGE_KEY, selectedKeywords);
            return {
                ...state,
                selectedKeywords: [...selectedKeywords]
            }
        });
    },
    selectedDateTimeFrom: determineStorageValueWithFallback(SELECTED_DATE_TIME_FROM_STORAGE_KEY, 0),
    setDateTimeFrom: (dateTime: number) => {
        setLocalStorageItem(SELECTED_DATE_TIME_FROM_STORAGE_KEY, dateTime)
        set(state => ({selectedDateTimeFrom: dateTime}))
    },
    selectedDateTimeUntil: determineStorageValueWithFallback(SELECTED_DATE_TIME_UNTIL_STORAGE_KEY, 0),
    setDateTimeUntil: (dateTime: number) => {
        setLocalStorageItem(SELECTED_DATE_TIME_UNTIL_STORAGE_KEY, dateTime)
        set(state => ({selectedDateTimeUntil: dateTime}))
    }
}));

function setLocalStorageItem(key: string, value: any) {
    if (value === UNSELECTED_KEY) {
        localStorage.removeItem(key);
    } else {
        localStorage.setItem(key, JSON.stringify(value));
    }
}

function updateOptionCounts(array1: LibraryFilterOption[], array2: LibraryFilterOption[]): LibraryFilterOption[] {
    const intersection = array1.filter((obj1) =>
        array2.some((obj2) => obj1.value === obj2.value)
    ).map(obj1 => {
        const {count} = array2.find(({value}) => obj1.value === value)!;
        return {...obj1, count: count};
    });
    const onlyInArray1 = array1.filter((obj1) =>
        !array2.some((obj2) => obj1.value === obj2.value)
    ).map(obj1 => {
        return {...obj1, count: 0};
    });
    const onlyInArray2 = array2.filter((obj2) =>
        !array1.some((obj1) => obj2.value === obj1.value)
    );

    return [
        ...intersection,
        ...onlyInArray1,
        ...onlyInArray2,
    ].sort(
        (a, b) => (a.value > b.value ? 1 : -1)
    );
}

