import React, {PropsWithChildren, useState} from 'react';
import {IconButton, Menu} from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';

interface ContextMenuProps extends PropsWithChildren {
}

const ContextMenu = (props: ContextMenuProps) => {
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>();

    const render = () => {
        if (!props.children) {
            return <></>
        }
        const open = Boolean(anchorEl);
        return <>
            <IconButton

                onClick={(event) => setAnchorEl(event.currentTarget)}
                size="small"
                sx={{ml: 2}}
                aria-controls={open ? 'account-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}>
                <MenuIcon/>

            </IconButton>
            <Menu anchorEl={anchorEl}

                  id="account-menu"
                  open={open}
                  onClose={() => setAnchorEl(null)}
                  onClick={() => setAnchorEl(null)}
                  PaperProps={{
                      elevation: 0,
                      sx: {
                          overflow: 'visible',
                          filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                          mt: 1.5,
                          '& .MuiAvatar-root': {
                              width: 32,
                              height: 32,
                              ml: -0.5,
                              mr: 1,
                          },
                          '&:before': {
                              content: '""',
                              display: 'block',
                              position: 'absolute',
                              top: 0,
                              right: 14,
                              width: 10,
                              height: 10,
                              bgcolor: 'background.paper',
                              transform: 'translateY(-50%) rotate(45deg)',
                              zIndex: 0,
                          },
                      },
                  }}
                  transformOrigin={{horizontal: 'right', vertical: 'top'}}
                  anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}>
                {props.children}
            </Menu>
        </>;
    }
    return render();
};

export default ContextMenu;