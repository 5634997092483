import {ComponentType, useEffect} from 'react';
import {useUserSessionStore} from "../../stores/UserSessionStore";
import {useNavigate} from "react-router-dom";

interface WithAuthenticationProps {
}

export default function withNoAuthentication(WrappedComponent: ComponentType,
                                             AlternativeComponent?: ComponentType) {
    return function (_props: WithAuthenticationProps) {
        const {isLoggedIn, isFetchingSession, fetchCurrentSession} = useUserSessionStore();
        const navigate = useNavigate();

        useEffect(() => {
            fetchCurrentSession();
        }, [fetchCurrentSession]);
        const forwardToLogin = () => {
            setTimeout(() =>
                navigate("/"), 0)
        }

        const render = () => {
            if (isFetchingSession) {
                return <></>;
            }
            if (!isLoggedIn) {
                return <WrappedComponent/>
            }
            if (AlternativeComponent) {
                return <AlternativeComponent/>;
            }
            forwardToLogin();
            return <></>;
        }

        return render();
    };
}
