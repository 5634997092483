import {ImageDetailsWrapper} from "./ImageDetails.style";
import {useGalleryStore} from "../../../stores/GalleryStore";

interface ImageDetailsProps {
}

const ImageDetails = (props: ImageDetailsProps) => {
    const {selectedImages,} = useGalleryStore();
    return <ImageDetailsWrapper>
    </ImageDetailsWrapper>
}

export default ImageDetails;