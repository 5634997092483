import React from "react";
import Diversity3OutlinedIcon from '@mui/icons-material/Diversity3Outlined';
import StoreMallDirectoryOutlinedIcon from "@mui/icons-material/StoreMallDirectoryOutlined";
import TopicIcon from "@mui/icons-material/Topic";
import PhotoLibraryIcon from "@mui/icons-material/PhotoLibrary";
import LibraryMusicOutlinedIcon from "@mui/icons-material/LibraryMusicOutlined";
import PersonIcon from '@mui/icons-material/Person';
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

export type PageHeaderIcon = "Store" | "Topic" | "PhotoLib" | "Projects" | "Crew" | "Profile" | "Upload";
export default function determineIcon(icon: PageHeaderIcon | undefined) {
    switch (icon) {
        case "Topic":
            return <TopicIcon sx={{fontSize: 60}}/>;
        case "PhotoLib":
            return <PhotoLibraryIcon sx={{fontSize: 60}}/>
        case "Upload":
            return <CloudUploadIcon sx={{fontSie: 60}}/>
        case "Projects":
            return <LibraryMusicOutlinedIcon sx={{fontSize: 60}}/>
        case "Crew":
            return <Diversity3OutlinedIcon sx={{fontSize: 60}}/>
        case "Profile":
            return <PersonIcon sx={{fontSize: 60}}/>;
        default:
        case "Store":
            return <StoreMallDirectoryOutlinedIcon sx={{fontSize: 60}}/>;

    }
}