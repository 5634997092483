import {CognitoUserSession} from "amazon-cognito-identity-js";
import {Auth} from "aws-amplify";

export default class UserSession {
    static _session: CognitoUserSession | undefined;

    static getIdJwtToken() {
        return this._session?.getIdToken().getJwtToken();
    }

    static async refreshToken() {
        console.log("refresh token")
        const cognitoUser = await Auth.currentAuthenticatedUser();
        const {refreshToken} = cognitoUser.getSignInUserSession();
        cognitoUser.refreshSession(refreshToken, (err: any, session: CognitoUserSession) => {
            if (err)
                Auth.signOut();
            this._session = session;
        });
    }
}