import {LogoCornerImage} from "./LogoCorner.style";
import logo from "./logo_text.png";
import {useNavigate} from "react-router-dom";

interface LogoCornerProps {
}

const LogoCorner = (props: LogoCornerProps) => {
    const navigate = useNavigate();
    const goToStartPage = () => {
        navigate("/")
    }

    return <LogoCornerImage src={logo} onClick={goToStartPage}/>
}

export default LogoCorner;