import React from 'react';
import {SideMenuToggleWrapper} from "./SideMenuToggle.style";
import {Menu, MenuItem, useProSidebar} from "react-pro-sidebar";
import KeyboardArrowLeftRoundedIcon from '@mui/icons-material/KeyboardArrowLeftRounded';
import KeyboardArrowRightRoundedIcon from '@mui/icons-material/KeyboardArrowRightRounded';

interface SideMenuToggleProps {
}

const SideMenuToggle = (props: SideMenuToggleProps) => {
    const {collapsed, collapseSidebar} = useProSidebar();
    const icon = collapsed ? <KeyboardArrowRightRoundedIcon/> : <KeyboardArrowLeftRoundedIcon/>;
    const message = collapsed ? "" : "Einklappen";
    return <SideMenuToggleWrapper>
        <Menu>
            <MenuItem
                icon={icon}
                onClick={() => collapseSidebar(!collapsed)}>
                {message}
            </MenuItem>
        </Menu>
    </SideMenuToggleWrapper>;
};

export default SideMenuToggle;