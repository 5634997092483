import {SignUpPageWrapper} from "./SignUpPage.style";
import {Button, TextField} from "@mui/material";
import React, {useState} from "react";
import {useUserSessionStore} from "../../../stores/UserSessionStore";

interface SignUpPageProps {
}

const SignUpPage = (props: SignUpPageProps) => {
    const [email, setEmail] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [errorMessage, setErrorMessage] = useState<string>("");
    const {signup} = useUserSessionStore();

    const handleSetNewPasswordClick = async () => {
        const result = await signup(email, password);
        if (result)
            setErrorMessage(result[0]);

    };

    return <SignUpPageWrapper>
        <h1>Registrieren</h1>

        <TextField
            id="email"
            name="email"
            label="Email"
            type="text"
            value={email}
            onChange={(event) => setEmail(event.target.value)}/>

        <TextField
            id="password"
            name="password"
            label="Passwort"
            type="password"
            value={password}
            onChange={(event) => setPassword(event.target.value)}/>

        <Button onClick={handleSetNewPasswordClick}>Registrieren</Button>
        <p>{errorMessage}</p>
    </SignUpPageWrapper>
}

export default SignUpPage;