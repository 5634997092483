import React, {PropsWithChildren} from 'react';
import {Title, TitledMenuWrapper} from "./TitledMenu.style";
import {Typography} from "@mui/material";
import {Menu, useProSidebar} from "react-pro-sidebar";

interface TitledMenuProps extends PropsWithChildren {
    title?: string;
    onSizeChanged?: (size: number) => {}
}

const TitledMenu = (props: TitledMenuProps) => {
    const {collapsed} = useProSidebar();
    const determineTitle = () => {
        if(!props.title  ){
            return <></>
        }
        return <Title className={collapsed?'hideTitle':''}>
            <Typography
                variant="body2"
                fontWeight={600}
                style={{opacity: collapsed ? 0 : 0.7,
                    letterSpacing: '0.5px',
                    fontSize:collapsed ? '2px' : 'inherit',
                    transition: 'all 0.5s'}}>
                {props.title}
            </Typography>
        </Title>;
    }
    return <TitledMenuWrapper>
        {determineTitle()}
        <Menu>
            {props.children}
        </Menu>
    </TitledMenuWrapper>;
};

export default TitledMenu;