import {ExportImageInputWrapper} from "./ExportImageInput.style";
import GalleryImage from "../../domain/Gallery/GalleryImage";
import ImageTile from "../Gallery/ImageTile/ImageTile";
import {TextField} from "@mui/material";
import Mustache from "mustache";
import moment from 'moment'
import "moment/locale/de"
import {useTopicsStore} from "../../stores/TopicsStore";
import Topic from "../../domain/Topic";
import {useEffect} from "react";
import {useProjectsStore} from "../../stores/ProjectsStore";

moment.locale('de');

interface ExportImageInputProps {
    image: GalleryImage;
    imageDescriptionChanged: (imageDescription: string) => void;
    imageDescriptionPattern: string;
}

function renderImageDescription(image: GalleryImage, topics: Topic[], template: string): string {
    const imageCreatedAt = moment(image.creationEpoch);
    const day = imageCreatedAt.format("dddd");
    const topic = topics.find(t => t.keyword.toLowerCase() === image.topicKeyword.toLowerCase());
    return Mustache.render(template, {...image, day, topic: topic?.name});
}

const ExportImageInput = (props: ExportImageInputProps) => {
    const {image, imageDescriptionPattern, imageDescriptionChanged} = props;
    const {activeProject} = useProjectsStore();
    const {topics, fetchForProject} = useTopicsStore();
    const defaultValue = renderImageDescription(image, topics, imageDescriptionPattern);

    useEffect(() => {
        if (!topics.length && activeProject) {
            fetchForProject(activeProject.tenantId, activeProject.projectId);
        }
    }, [topics, fetchForProject, activeProject])

    useEffect(() => {
        imageDescriptionChanged(defaultValue);
    }, [imageDescriptionChanged, defaultValue])
    return <ExportImageInputWrapper>
        <ImageTile image={image}
                   size={130}
                   imageSize={150}
                   selected={false}/>
        <TextField
            label="Bildunterschrift"
            multiline
            rows={3}
            sx={{width: 400}}
            onChange={event => props.imageDescriptionChanged(event.target.value)}
            value={defaultValue}
            variant="filled"
        />
    </ExportImageInputWrapper>
}

export default ExportImageInput;