import React from 'react';
import {Route, Routes} from 'react-router-dom';
import NoMatchPage from './pages/NoMatchPage/NoMatchPage';
import MainFrame from "./pages/MainFrame/MainFrame";
import provideMenuRoutes from "./shared/MenuRouteProvider";
import FlatpicMenus from "./FlatpicMenus";
import LoginPage from "./pages/Authentication/LoginPage/LoginPage";
import ResetPasswordPage from "./pages/Authentication/ResetPasswordPage/ResetPasswordPage";
import {Amplify} from "aws-amplify";
import AmplifyConfig from "./amplify.config";
import NewPasswordRequired from "./pages/Authentication/NewPasswordRequired/NewPasswordRequired";
import SignUpPage from "./pages/Authentication/SignUpPage/SignUpPage";
import SelectUserRolePage from "./pages/SelectUserRolePage/SelectUserRolePage";
import SelectProjectPage from "./pages/SelectProjectPage/SelectProjectPage";
import LibraryPage from "./pages/LibraryPage/LibraryPage";
import MyProfilePage from "./pages/MyProfilePage/MyProfilePage";
import WelcomePage from "./pages/WelcomePage/WelcomePage";

Amplify.configure(AmplifyConfig);

function App() {

    return <Routes>
        <Route path="/" element={<MainFrame/>}>
            <Route index={true} element={<WelcomePage/>}/>
            {provideMenuRoutes(FlatpicMenus)}
            <Route path="/my-profile" element={<MyProfilePage/>}/>
        </Route>
        <Route path="/login" element={<LoginPage/>}/>
        <Route path="/library" element={<LibraryPage/>}/>
        <Route path="/select-role" element={<SelectUserRolePage/>}/>
        <Route path="/select-project" element={<SelectProjectPage/>}/>
        <Route path="/reset-password" element={<ResetPasswordPage/>}/>
        <Route path="/new-password-required" element={<NewPasswordRequired/>}/>
        <Route path="/signup" element={<SignUpPage/>}/>
        <Route path="*" element={<NoMatchPage/>}/>
    </Routes>;
}

export default App;
