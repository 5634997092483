import styled from "styled-components";

export const PageSkeletonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const PageSkeletonHeader = styled.div`
  display: flex;
`;

export const PageSkeletonIcon = styled.div`
  width: 4em;
  height: 4em;
  
`;

export const PageSkeletonTitle = styled.div`
  width: 15em;
  margin-left: 20px;
  height: 4em;
`;

export const PageSkeletonBody = styled.div`
  width: 100%;
  margin-top: 20px;
  height: calc(100% - 4em - 20px);
`;

