import styled from "styled-components";

export const TitledMenuWrapper = styled.div`
    
`;

export const Title = styled.div`
  padding: 0 24px;
  margin-bottom: 8px;
  margin-top: 32px;
  transition: all 0.5s;
  
  &.hideTitle{
    margin-bottom: 0;
    margin-top: 0;
  }
`;