import {ChipWrapper, Headline, ListChipFilterWrapper, OptionCount} from "./ListChipFilter.style";
import {LibraryFilterOption} from "../../../../stores/LibraryFilterStore";
import {Chip} from "@mui/material";
import React, {useCallback} from "react";

interface ListChipFilterProps {
    addValue: (value: string) => void;
    removeValue: (value: string) => void;
    selectedValues: string[];
    options: LibraryFilterOption[];
    label: string;
}

const ListChipFilter = (props: ListChipFilterProps) => {
    const {
        options,
        label,
        selectedValues,
        removeValue,
        addValue
    } = props;

    const optionIsVisible = useCallback((option: LibraryFilterOption) => {
        const isSelected = selectedValues.indexOf(option.value) !== -1;
        const hasCount = option.count > 0;
        return isSelected || hasCount;
    }, [selectedValues])

    const renderChip = useCallback((option: LibraryFilterOption) => {
        const isSelected = selectedValues.indexOf(option.value) !== -1;
        if (isSelected) {
            return <Chip variant="filled"
                         size="small"
                         key={option.value}
                         label={option.value}
                         color="primary"
                         onDelete={() => removeValue(option.value)}/>
        }

        return <Chip variant="outlined"
                     size="small"
                     key={option.value}
                     label={option.value}
                     deleteIcon={<OptionCount>{option.count}</OptionCount>}
                     onDelete={() => addValue(option.value)}
                     onClick={() => addValue(option.value)}/>
    }, [selectedValues, addValue, removeValue]);

    return <ListChipFilterWrapper>
        <Headline>{label}</Headline>
        <ChipWrapper>
            {options.filter(optionIsVisible).map(option =>
                renderChip(option))}
        </ChipWrapper>
    </ListChipFilterWrapper>
}

export default ListChipFilter;