import {create} from "zustand";

export enum FILE_ERROR {
    NO_AUTHOR = "NO_AUTHOR",
    NO_KEYWORD = "NO_KEYWORD",
    SERVER_ERROR = "SERVER_ERROR",
    FILE_TO_BIG = "FILE_TO_BIG",
    NO_DATE_TIME_ORIGINAL = "NO_DATE_TIME_ORIGINAL",
    NO_IMAGE_FILE = "NO_IMAGE_FILE"
}

export interface FileWithKeyword {
    file: File;
    topicKeyword?: string
}

export interface FailedFile {
    file: FileWithKeyword,
    error: FILE_ERROR[]
}

export interface FileUploadStore {
    pendingFiles: FileWithKeyword[];
    uploadedFiles: FileWithKeyword[];
    uploadingFiles: { file: FileWithKeyword, progress: number }[];
    failedFiles: FailedFile[];
    currentUploadSize: number,
    addFiles: (files: FileWithKeyword[]) => void;
    addFileToUploading: (file: FileWithKeyword) => void;
    changeProgress: (file: FileWithKeyword, progress: number) => void;
    uploadComplete: (file: FileWithKeyword) => void;
    uploadFailed: (file: FileWithKeyword, error: FILE_ERROR[]) => void;
    cancelFailed: (file: File) => void;
}

export const useFileUploadStore: () => FileUploadStore = create<FileUploadStore>()((set, get) => ({
    pendingFiles: [],
    uploadedFiles: [],
    uploadingFiles: [],
    failedFiles: [],
    currentUploadSize: 0,
    currentUploads: 0,
    addFiles: (files: FileWithKeyword[]) => {
        set((state) => {
            const pendingFiles = [...state.pendingFiles, ...files];
            return {
                ...state,
                currentUploadSize: state.currentUploadSize + files.length,
                pendingFiles
            };
        });
    },
    addFileToUploading: (file: FileWithKeyword) => {
        const {pendingFiles} = get();
        const index = pendingFiles.indexOf(file);
        if (index >= 0) {
            pendingFiles.splice(index, 1);
        }

        set((state) =>
            ({
                ...state,
                pendingFiles,
                uploadingFiles: [...state.uploadingFiles, {file, progress: 0}]
            }));
    },
    changeProgress: (file: FileWithKeyword, progress: number) => {
        const {uploadingFiles} = get();
        const uploadingFile = uploadingFiles.find(f => f.file === file);
        if (uploadingFile) {
            uploadingFile.progress = progress;
        }
        set(state => ({
            ...state,
            uploadingFiles
        }));
    },
    uploadComplete: (file: FileWithKeyword) => {
        const {uploadingFiles} = get();
        const find = uploadingFiles.find(f => f.file === file);
        if (!find) {
            return;
        }

        uploadingFiles.splice(uploadingFiles.indexOf(find), 1);
        set(state =>
            ({
                ...state,
                currentUploadSize: isEmpty(state.pendingFiles) && isEmpty(state.uploadingFiles) ? 0 : state.currentUploadSize,
                uploadingFiles,
                uploadedFiles: [...state.uploadedFiles, file]
            }));
    },
    uploadFailed: async (file: FileWithKeyword, error: FILE_ERROR[]) => {
        const {uploadingFiles} = get();
        const find = uploadingFiles.find(f => f.file.file === file.file);
        if (find) {
            uploadingFiles.splice(uploadingFiles.indexOf(find), 1);
        }

        set(state =>
            ({
                ...state,
                uploadingFiles,
                currentUploadSize: isEmpty(state.pendingFiles) && isEmpty(state.uploadingFiles) ? 0 : state.currentUploadSize,
                failedFiles: [...state.failedFiles, {file, error}]
            }));
    },
    cancelFailed: (file: File) => {
        set(state =>
            ({
                ...state,
                failedFiles: state.failedFiles.filter(x => x.file.file != file)
            }));
    }
}));

function isEmpty(array: any[]): boolean {
    return array.length === 0;
}
