import {create} from "zustand";
import GalleryApi, {FetchOptions} from "../api/GalleryApi";
import GalleryImage from "../domain/Gallery/GalleryImage";
import {useLibraryFilterStore} from "./LibraryFilterStore";
import {determineStorageValueWithFallback} from "./determineStorageValue";


export type GalleryStore = {
    images: GalleryImage[];
    selectedImages: GalleryImage[];
    addImages: (images: GalleryImage[]) => void;
    clearImages: () => void;
    size: number;
    setSize: (size: number) => void;
    gap: number;
    setGap: (gap: number) => void;
    isMultiSelect: boolean;
    setMultiSelect: (isMultiSelect: boolean) => void;
    selectImage: (image: GalleryImage) => void;
    unselectImage: (image: GalleryImage) => void;
    clearSelection: () => void;
    isSelected: (image: GalleryImage) => boolean;
    fetchImages: (options: FetchOptions) => Promise<void>;
    fetchImagesStream: (options: FetchOptions) => Promise<void>;
    hitCount: number;
    isFetching: boolean;
    deleteImages: (images: GalleryImage[]) => Promise<boolean>;
    activeReader: ReadableStreamDefaultReader<string> | undefined;
}

const GALLERY_SIZE = "GALLERY_SIZE";
const GALLERY_GAP = "GALLERY_GAP";
const GALLERY_MULTISELECTION = "GALLERY_MULTISELECTION";

export const useGalleryStore = create<GalleryStore>()((set, get) => ({
    images: [],
    selectedImages: [],
    hitCount: 0,
    isFetching: false,
    activeReader: undefined,
    size: determineStorageValueWithFallback(GALLERY_SIZE, 300),
    setSize: (size) => {
        set(state => ({...state, size}));
        localStorage.setItem(GALLERY_SIZE, JSON.stringify(size));
    },
    gap: determineStorageValueWithFallback(GALLERY_GAP, 10),
    setGap: (gap) => {
        set(state => ({...state, gap}));
        localStorage.setItem(GALLERY_GAP, JSON.stringify(gap));
    },
    isMultiSelect: determineStorageValueWithFallback(GALLERY_MULTISELECTION, false),
    setMultiSelect: (isMultiSelect) => {
        const {selectedImages} = get()
        const firstSelectedImage = selectedImages.length > 0 ? [selectedImages.shift()!] : selectedImages;
        set(state => ({
            ...state,
            isMultiSelect,
            selectedImages: firstSelectedImage
        }));
        localStorage.setItem(GALLERY_MULTISELECTION, JSON.stringify(isMultiSelect));
    },
    addImages: async (images) => {
        set(state => ({...state, images: [...state.images, ...images]}));
    },
    clearImages: () => {
        set(state => ({...state, images: []}));
    },
    selectImage: (image) => {
        const {isSelected, selectedImages, isMultiSelect} = get();
        if (isSelected(image)) {
            return;
        }
        if (!isMultiSelect && selectedImages.length > 0) {
            set(state => ({...state, selectedImages: [image]}));
            return;
        }
        set(state => ({...state, selectedImages: [...state.selectedImages, image]}));
    },
    unselectImage: (image) => {
        const {selectedImages} = get();
        const index = selectedImages.indexOf(image);
        if (index >= 0) {
            selectedImages.splice(index, 1);
            set(state => ({...state, selectedImages}));
        }
    },
    clearSelection: () =>{
        set(state => ({...state, selectedImages: []}));
    },
    isSelected: (image) => {
        return get().selectedImages.some(i => i.key === image.key);
    },
    fetchImages: async (options: FetchOptions) => {
        set(state => ({...state, isFetching: true}));
        const galleryImageResponse = await GalleryApi.INSTANCE.fetchForProject(options);
        set(state => ({
            ...state, images: galleryImageResponse.hits,
            hitCount: galleryImageResponse.hitCount,
            isFetching: false
        }));
        const {aggregation} = galleryImageResponse;
        useLibraryFilterStore.getState().setOptionCounts({
            ...aggregation as any
        });
    },
    fetchImagesStream: async (options: FetchOptions) => {
        if (get().isFetching) {
            await get().activeReader?.cancel("depricated request");
        }
        set(state => ({...state, isFetching: true, images: [], hitCount: 0}));

        const response = await GalleryApi.INSTANCE.fetchImageStream(options);
        const reader = response.body!.pipeThrough(new TextDecoderStream()).getReader();
        await get().activeReader?.cancel("depricated request");
        set(state => ({...state, activeReader: reader}));
        let accumulatedData = '';
        let partialData = '';

        set(state => ({...state, isFetching: true, images: [], hitCount: 0}));
        while (true) {
            const {value, done} = await reader.read();
            if (done) break;

            partialData = value;
            // Prüfen, ob ein vollständiges JSON-Objekt vorhanden ist
            const objects = partialData.split('\n');
            if (objects.length > 1) {

                // Das letzte Element enthält einen unvollständigen Wert
                const lastElementIndex = objects.length - 1;
                accumulatedData += objects.slice(0, lastElementIndex).join('\n');

                // Parsen und Aktualisieren des Zustands mit den neuen Daten
                const jsonData = accumulatedData.split('\n').map(item => JSON.parse(item));
                if (jsonData[0].hasOwnProperty("hitCount")) {
                    const meta = jsonData.shift();
                    set(state => ({...state, hitCount: meta.hitCount}));
                    useLibraryFilterStore.getState().setOptionCounts({
                        ...meta.aggregation as any
                    });
                }

                set(state => ({
                    ...state, images: [...state.images, ...jsonData]
                }));
                accumulatedData = objects[lastElementIndex];
            } else {
                accumulatedData += partialData;
            }
        }

        set(state => ({
            ...state,
            isFetching: false
        }));
    },
    deleteImages: async (images: GalleryImage[]) => {
        const result = await GalleryApi.INSTANCE.deleteImages(images)
        set(state => ({
            ...state,
            images: state.images.filter(i => imageWasNotDeleted(i, result.deletedImages)),
            selectedImages: state.selectedImages.filter(i => imageWasNotDeleted(i, result.deletedImages))
        }));
        return false
    }
}));

function imageWasNotDeleted(image: GalleryImage, deletedImages: GalleryImage[]): boolean {
    const {key, bucketName} = image;
    const wasDeleted = deletedImages.some(di => di.key === key && di.bucketName === bucketName);
    return !wasDeleted;
}