import {SelectionListWrapper} from "./SelectionList.style";
import {ButtonGroup} from "@mui/material";
import SelectedGallery from "../../../components/Gallery/SelectedGallery";
import {Heading} from "../LibraryPage.style";
import ExportDialog from "../../../components/ExportDialog/ExportDialog";
import ClearSelectionButton from "./ClearSelectionButton/ClearSelectionButton";

interface SelectionListProps {
}

const SelectionList = (props: SelectionListProps) => {
    /*

                <Button size="small"><PlaylistAddIcon/></Button>
                <LoadingButton size="small"><DeleteSweepIcon/></LoadingButton>
     */
// <DeleteImagesDialog/>
    return <SelectionListWrapper>
        <Heading>Auswahl</Heading>
        <ButtonGroup variant="text" aria-label="text button group">
            <ExportDialog/>

            <ClearSelectionButton/>
        </ButtonGroup>
        <SelectedGallery size={80} gap={2}/>

    </SelectionListWrapper>
}

export default SelectionList;