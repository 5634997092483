import {InputEmailStepWrapper} from "./InputEmailStep.style";
import {Button, TextField} from "@mui/material";
import React, {useState} from "react";
import {useUserSessionStore} from "../../../../stores/UserSessionStore";

interface InputEmailStepProps {
    nextStep: (email: string) => void;
}

const InputEmailStep = (props: InputEmailStepProps) => {
    const {nextStep} = props;
    const {forgotPassword} = useUserSessionStore();
    const [email, setEmail] = useState<string>("");
    const handleResetClick = async () => {
        await forgotPassword(email);
        nextStep(email);
    };

    return <InputEmailStepWrapper>
        <h1>Passwort vergessen</h1>
        <p>Bitte gib die Email deines Accounts an:</p>
        <TextField
            id="email"
            name="email"
            label="Email"
            fullWidth
            type="text"
            value={email}
            onChange={(event) => setEmail(event.target.value)}/>
        <Button
            onClick={handleResetClick}
            color="primary"
            variant="contained"
            fullWidth>Neues Passwort anfordern</Button>
    </InputEmailStepWrapper>
}

export default InputEmailStep;