import styled from "styled-components";
import SideMenu from "../../components/SideMenu/SideMenu";

export const MainFrameWrapper = styled.div`
  display: flex;
  height: 100vh;
`;

export const ContentWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const MainContent = styled.div`
  flex: 1;
  overflow: auto;
  padding: 20px 50px 50px;
`;

export const SideMenuWrapper = styled(SideMenu)`
`;
export const MainHeaderWrapper = styled.div`
  gap: 20px;
  padding: 10px 50px;
  display: flex;
  justify-content: flex-end;
`;