import React from 'react';
import {LoginFormWrapper} from "./LoginForm.style";
import {FormikProps, withFormik} from "formik";
import {Button, TextField} from "@mui/material";
import * as Yup from 'yup';
import {Link} from 'react-router-dom';

interface LoginFormProps {
    doLogin: (username: string, password: string) => Promise<string[]>;
}

interface FormValues {
    email: string;
    password: string;
}

const LoginInnerForm = (props: LoginFormProps & FormikProps<FormValues>) => {
    return <LoginFormWrapper onSubmit={props.handleSubmit}>
        <TextField
            fullWidth
            variant="standard"
            id="email"
            name="email"
            label="Email"
            value={props.values.email}
            onChange={props.handleChange}
            error={props.touched.email && Boolean(props.errors.email)}
            helperText={props.touched.email && props.errors.email}/>
        <TextField
            fullWidth
            variant="standard"
            id="password"
            name="password"
            label="Password"
            type="password"
            value={props.values.password}
            onChange={props.handleChange}
            error={props.touched.password && Boolean(props.errors.password)}
            helperText={props.touched.password && props.errors.password}/>

        <Button color="primary"
                variant="contained"
                fullWidth
                type="submit"
                disabled={props.isSubmitting}>
            Login
        </Button>
        <Button variant="text"
                component={Link}
                to="/reset-password"
                fullWidth>passwort vergessen</Button>
    </LoginFormWrapper>;
};


const LoginForm = withFormik<LoginFormProps, FormValues>({
    mapPropsToValues: props => {
        return {
            email: '',
            password: ''
        }
    },
    validationSchema: Yup.object({
        email: Yup.string().email("Keine Gültige Email")
            .required("Pflichtfeld"),
        password: Yup.string()
            .min(5, "Mind. 5 Zeichen")
            .required("Pflichtfeld")
    }),
    handleSubmit: async (values, {setSubmitting, props}) => {
        const errors = await props.doLogin(values.email, values.password);
        setSubmitting(false);
    }
})(LoginInnerForm);

export default LoginForm;