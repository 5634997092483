import AbstractApi from "./AbstractApi";
import Project from "../domain/Project";
import MomentMapper from "../shared/MomentMapper";
import CrewMember from "../domain/CrewMember";
import PreSignedPostUrl from "../domain/PreSignedPostUrl";
import Topic from "../domain/Topic";
import {Scene} from "../domain/Scene";

export default class TenantApi extends AbstractApi {
    public static _instance: TenantApi;

    private constructor() {
        super("Tenant", process.env.REACT_APP_TENANT_API);
    }

    public static get INSTANCE(): TenantApi {
        if (!this._instance) {
            this._instance = new TenantApi()
        }
        return this._instance;
    }

    public async fetchAllProjects(tenantId: string) {
        const result = await this.getRequest<Project[]>(`/${tenantId}/projects`);
        return result.map(this.mapDatesToMoment);
    }

    private mapDatesToMoment(project: Project): Project {
        project.dateFrom = MomentMapper.fromString((project.dateFrom as any));
        project.dateUntil = MomentMapper.fromString((project.dateUntil as any));
        return project;
    }

    private mapDatesToString(project: Pick<Project, "dateUntil" | "dateFrom">) {
        project.dateFrom = MomentMapper.toString(project.dateFrom) as any;
        project.dateUntil = MomentMapper.toString(project.dateUntil) as any;
        return project;
    }

    async createProject(project: Pick<Project, "name" | "dateFrom" | "dateUntil" | "hashtag" | "imageDescription" | "tenantId">) {
        return await this.postRequest<boolean>(`/${project.tenantId}/projects`, this.mapDatesToString(project));
    }

    async updateProject(project: Project) {
        const {tenantId, projectId} = project;
        return await this.putRequest<boolean>(`/${tenantId}/projects/${encodeURIComponent(projectId)}`, this.mapDatesToString(project));
    }

    public async fetchTopics(tenantId: string, projectId: string) {
        return await this.getRequest<Topic[]>(`/${tenantId}/projects/${projectId}/topics`);
    }

    async createTopic(topic: Topic) {
        return await this.postRequest<boolean>(`/${topic.tenantId}/projects/${topic.projectId}/topics`, topic);
    }

    async createTopics(tenantId: string, projectId: string, topics: Topic[]) {
        return await this.postRequest<boolean>(`/${tenantId}/projects/${projectId}/topics/batch`, topics);
    }

    async updateTopic(topic: Topic) {
        const {tenantId, projectId, topicId} = topic;
        return await this.putRequest<boolean>(`/${tenantId}/projects/${encodeURIComponent(projectId)}/topics/${topicId}`, topic);
    }

    async deleteTopic(topic: Topic) {
        const {tenantId, projectId, topicId} = topic;
        return await this.deleteRequest<boolean>(`/${tenantId}/projects/${encodeURIComponent(projectId)}/topics/${topicId}`);
    }

    async createScene(scene: Scene) {
        const {tenantId, projectId, topicId} = scene;
        return await this.postRequest<boolean>(`/${tenantId}/projects/${encodeURIComponent(projectId)}/topics/${topicId}/scenes`, scene);
    }

    async updateScene(scene: Scene) {
        const {tenantId, projectId, topicId, sceneId} = scene;
        return await this.putRequest<boolean>(`/${tenantId}/projects/${encodeURIComponent(projectId)}/topics/${topicId}/scenes/${sceneId}`, scene);
    }

    async deleteScene(scene: Scene) {
        const {tenantId, projectId, topicId, sceneId} = scene;
        return await this.deleteRequest<boolean>(`/${tenantId}/projects/${encodeURIComponent(projectId)}/topics/${topicId}/scenes/${sceneId}`);
    }

    async findSuggestionsForInvitation(tenantId: string, projectId: string, searchValue: string) {
        return await this.postRequest<{
            hits: Array<CrewMember>,
            hitCount: number
        }>(`${this.determineUrlPrefix(tenantId, projectId)}/crew/suggest`, {searchValue});
    }

    async inviteCrewMember(tenantId: string, projectId: string, email: string) {
        return await this.postRequest<boolean>(`${this.determineUrlPrefix(tenantId, projectId)}/crew/invite`, {email});
    }

    async findCrewMembersForProject(tenantId: string, projectId: string) {
        return await this.getRequest<Array<CrewMember>>(`${this.determineUrlPrefix(tenantId, projectId)}/crew`);
    }

    async removeCrewMemberFromProject(tenantId: string, projectId: string, userName: string) {
        return await this.postRequest<boolean>(`${this.determineUrlPrefix(tenantId, projectId)}/crew/remove`, {userName});
    }

    private determineUrlPrefix(tenantId: string, projectId: string): string {
        return `/${tenantId}/projects/${encodeURIComponent(projectId)}`;
    }

    public async createPostImageUrl(tenantId: string, projectId: string, fileSize: number, topicKeyword: string, fileName?: string) {
        let url = `${this.determineUrlPrefix(tenantId, projectId)}/uploadImage`;
        if (fileName) {
            url += `?fileName=${fileName}`;
        }
        return this.postRequest<PreSignedPostUrl>(url, {
            fileSize,
            topicKeyword
        });
    }
}