import {Moment} from "moment";
import moment from 'moment';

export default class MomentMapper {
    private static readonly PATTERN: string = "YYYYMMDD";

    private constructor() {
    }

    public static fromString(value: string): Moment {
        return moment(value, MomentMapper.PATTERN);
    }

    public static toString(date: Moment): string {
        return date.format(MomentMapper.PATTERN);
    }
}