import {Dialog, DialogTitle, List, ListItem, ListItemButton, ListItemText} from "@mui/material";
import Project from "../../domain/Project";
import {useCallback, useEffect} from "react";
import {useProjectsStore} from "../../stores/ProjectsStore";
import {enqueueSnackbar} from "notistack";
import {useTenantStore} from "../../stores/TenantStore";

interface SelectProjectDialogProps {
    open: boolean;
    onClose: (activatedProject?: Project) => void;
}

const SelectProjectDialog = (props: SelectProjectDialogProps) => {
    const {open, onClose} = props;
    const {selectedTenant} = useTenantStore();
    const {projects, fetchAllProjectsForTenant, activateProject} = useProjectsStore();


    useEffect(() => {
        if (!selectedTenant)
            return

        // Todo nach beta: crews müssen eine eigene lambda aufrufen oder die aktuelle lambda muss die leseberechtigung prüfen
        fetchAllProjectsForTenant(selectedTenant.tenantId);
    }, [fetchAllProjectsForTenant, selectedTenant]);

    const handleClose = useCallback(() => {
        onClose();
    }, [onClose]);

    const handleClickProjectItem = useCallback((project: Project) => {
        activateProject(project.projectId);
        enqueueSnackbar(`${project.name} ist nun aktiv`, {
            variant: "info"
        });
        onClose();
    }, [activateProject, onClose]);

    return <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Projekt auswählen</DialogTitle>
        <List sx={{pt: 0}}>
            {projects.map((project) => (
                <ListItem disableGutters key={project.projectId}>
                    <ListItemButton onClick={() => handleClickProjectItem(project)} key={project.projectId}>
                        <ListItemText primary={project.name}/>
                    </ListItemButton>
                </ListItem>
            ))}
        </List>
    </Dialog>
}

export default SelectProjectDialog;