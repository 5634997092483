import {useLibraryFilterStore} from "../../../stores/LibraryFilterStore";
import React from "react";
import DateTimeFilter from "./DateTimeFilter/DateTimeFilter";

interface LibraryFilterProps {
}

const DateTimeUntilFilter = (props: LibraryFilterProps) => {
    const {
        selectedDateTimeUntil,
        setDateTimeUntil
    } = useLibraryFilterStore();

    return <DateTimeFilter selectedValue={selectedDateTimeUntil}
                           setValue={setDateTimeUntil}
                           label="Zeitraum bis"/>;
}

export default DateTimeUntilFilter;