import {useLibraryFilterStore} from "../../../stores/LibraryFilterStore";
import React from "react";
import ListChipFilter from "./ListChipFilter/ListChipFilter";

interface LibraryFilterProps {
}

const KeywordsFilter = (props: LibraryFilterProps) => {
    const {
        keywords,
        selectedKeywords,
        addKeyword,
        removeKeyword
    } = useLibraryFilterStore();

    return <ListChipFilter options={keywords}
                           selectedValues={selectedKeywords}
                           addValue={addKeyword}
                           removeValue={removeKeyword}
                           label="Schlagwörter"/>
}

export default KeywordsFilter;