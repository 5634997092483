import MenuContainer, {MenuStructure} from "./MenuContainer";
import React, {ReactNode} from "react";
import {Link} from "react-router-dom";
import TitledMenu from "../components/SideMenu/TitledMenu/TitledMenu";
import {MenuItem, SubMenu} from "react-pro-sidebar";
import {v4 as uuid} from 'uuid';

export interface MenuItemsProviderOptions {
    menuTitleSubstitutionProvider?: (menuTitle: string) => string;
    shouldShowMenu?: (menuId: MenuContainer) => boolean;
};
export default function provideMenuItems(menuContainerList: MenuContainer[], options: MenuItemsProviderOptions) {
    return menuContainerList.filter(i => options.shouldShowMenu?.(i) ?? true)
        .map(i => createMenuContainer(i, options));
}

function createMenuContainer(menu: MenuContainer, options: MenuItemsProviderOptions): React.ReactNode {
    const title = options.menuTitleSubstitutionProvider?.(menu.name || '') ?? menu.name;
    return <TitledMenu title={title} key={uuid()}>
        {menu.menus.map(createMenu)}
    </TitledMenu>
}

function createMenu(menu: MenuStructure): ReactNode {
    if (menu.children)
        return <SubMenu key={uuid()}>
            {menu.children.map(createMenu)}
        </SubMenu>

    return <MenuItem icon={menu.icon}
                     key={uuid()}
                     title={menu.label}
                     component={<Link to={menu.route.to}/>}>{menu.label}</MenuItem>;
}