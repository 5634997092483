import {ResetPasswordPageWrapper} from "./ResetPasswordPage.style";
import React, {useState} from "react";
import InputEmailStep from "./InputEmailStep/InputEmailStep";
import InputCodeStep from "./InputCodeStep/InputCodeStep";
import InputPasswordStep from "./InputPasswordStep/InputPasswordStep";
import {useNavigate} from "react-router-dom";
import LogoCorner from "../../../components/LogoCorner/LogoCorner";
import FlatpicBox from "../../../components/FlatpicBox/FlatpicBox";

interface ResetPasswordPageProps {
}

type WorkflowStep = "INPUT_EMAIL" | "INPUT_CODE" | "INPUT_PASSWORD";

const ResetPasswordPage = (props: ResetPasswordPageProps) => {
    const [email, setEmail] = useState<string>("");
    const [code, setCode] = useState<string>("");
    const [workflowStep, setWorkflowStep] = useState<WorkflowStep>("INPUT_EMAIL");
    const navigate = useNavigate();
    const completeInputEmailStep = (email: string) => {
        setEmail(email);
        setWorkflowStep("INPUT_CODE");
    };
    const completeInputCodeStep = (code: string) => {
        setCode(code);
        setWorkflowStep("INPUT_PASSWORD");
    };
    const completeInputPasswordStep = () => {
        navigate("/login");
    };

    const determineStep = () => {
        switch (workflowStep) {
            case "INPUT_EMAIL":
                return <InputEmailStep nextStep={completeInputEmailStep}/>
            case "INPUT_CODE":
                return <InputCodeStep email={email}
                                      nextStep={completeInputCodeStep}/>
            case "INPUT_PASSWORD":
                return <InputPasswordStep email={email}
                                          code={code}
                                          nextStep={completeInputPasswordStep}/>
        }
    }
    return <ResetPasswordPageWrapper>
        <LogoCorner/>
        <FlatpicBox>
            {determineStep()}
        </FlatpicBox>
    </ResetPasswordPageWrapper>
}

export default ResetPasswordPage;