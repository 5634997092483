import {useLibraryFilterStore} from "../../../stores/LibraryFilterStore";
import React from "react";
import DateTimeFilter from "./DateTimeFilter/DateTimeFilter";

interface LibraryFilterProps {
}

const DateTimeFromFilter = (props: LibraryFilterProps) => {
    const {
        selectedDateTimeFrom,
        setDateTimeFrom
    } = useLibraryFilterStore();

    return <DateTimeFilter selectedValue={selectedDateTimeFrom}
                           setValue={setDateTimeFrom}
                           label="Zeitraum ab"/>;
}

export default DateTimeFromFilter;